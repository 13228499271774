/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeatureDto } from '../model';
// @ts-ignore
import { FeatureStrategyConfigurationDto } from '../model';
/**
 * AdminFeatureControllerApi - axios parameter creator
 * @export
 */
export const AdminFeatureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStrategiesConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/feature/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES'} feature 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleFeature: async (feature: 'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES', enabled: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('toggleFeature', 'feature', feature)
            // verify required parameter 'enabled' is not null or undefined
            assertParamExists('toggleFeature', 'enabled', enabled)
            const localVarPath = `/api/admin/feature/toggle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (feature !== undefined) {
                localVarQueryParameter['feature'] = feature;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FeatureDto} featureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (featureDto: FeatureDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureDto' is not null or undefined
            assertParamExists('updateFeature', 'featureDto', featureDto)
            const localVarPath = `/api/admin/feature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFeatureControllerApi - functional programming interface
 * @export
 */
export const AdminFeatureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFeatureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<FeatureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStrategiesConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<FeatureStrategyConfigurationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStrategiesConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES'} feature 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleFeature(feature: 'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES', enabled: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleFeature(feature, enabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FeatureDto} featureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(featureDto: FeatureDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(featureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFeatureControllerApi - factory interface
 * @export
 */
export const AdminFeatureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFeatureControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFeatures(options?: any): Promise<Array<FeatureDto>> {
            return localVarFp.getAllFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStrategiesConfiguration(options?: any): Promise<Array<FeatureStrategyConfigurationDto>> {
            return localVarFp.getStrategiesConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES'} feature 
         * @param {boolean} enabled 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleFeature(feature: 'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES', enabled: boolean, options?: any): Promise<void> {
            return localVarFp.toggleFeature(feature, enabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeatureDto} featureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(featureDto: FeatureDto, options?: any): Promise<void> {
            return localVarFp.updateFeature(featureDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFeatureControllerApi - object-oriented interface
 * @export
 * @class AdminFeatureControllerApi
 * @extends {BaseAPI}
 */
export class AdminFeatureControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureControllerApi
     */
    public getAllFeatures(options?: AxiosRequestConfig) {
        return AdminFeatureControllerApiFp(this.configuration).getAllFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureControllerApi
     */
    public getStrategiesConfiguration(options?: AxiosRequestConfig) {
        return AdminFeatureControllerApiFp(this.configuration).getStrategiesConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES'} feature 
     * @param {boolean} enabled 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureControllerApi
     */
    public toggleFeature(feature: 'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT' | 'PAYMENT_MARKING_MAX_PRICE' | 'STANDARD_REFUND_MAX_PRICE' | 'NO_LIMIT_VISIT_CHANGE' | 'EMAIL_CHANGE_REQUEST' | 'ADDRESS_CHANGED_BAR' | 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT' | 'VIDEO_STREAMING' | 'CHUNK_UPLOAD_FOR_LARGE_FILES', enabled: boolean, options?: AxiosRequestConfig) {
        return AdminFeatureControllerApiFp(this.configuration).toggleFeature(feature, enabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeatureDto} featureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureControllerApi
     */
    public updateFeature(featureDto: FeatureDto, options?: AxiosRequestConfig) {
        return AdminFeatureControllerApiFp(this.configuration).updateFeature(featureDto, options).then((request) => request(this.axios, this.basePath));
    }
}
