import SecurityService from "../service/security.service";
import EventBus from "../utility/event.bus";
import {instance as vue} from "../main";
import Axios from "axios";
import Qs from "qs";
import {saveAs} from "file-saver";
import moment from "moment";
import ProcessingUtils from "../utility/processing.utils";


Axios.defaults.headers.common = {
    "Content-Type": "application/json"
};


Axios.interceptors.request.use(
    config => {
        if (!config.headers['Authorization']) {
            config.headers['Authorization'] = SecurityService.isAccessTokenSet() ?
                `Bearer ${SecurityService.getAccessToken()}` : undefined;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


Axios.interceptors.request.use(
    config => {
        config.headers['Accept-Language'] = vue.getLocaleConfig().language;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


Axios.interceptors.response.use(
    response => {
        const {config: {method , url}} = response;
        return response.data;
    },
    error => {

        //Not an unauthorized response

        if (error.response.status !== 401) {
            return Promise.reject(error);
        } else {
            const originalRequest = error.config;

            EventBus.$emit('show-error-info', {
                message: 'error.AUTHORIZATION',
            });
        }
    }
);


const generic = {

    get(path, params = {}, headers = {}) {
        return Axios.get(`${path}`, {
            params: params,
            paramsSerializer: params => {
                return Qs.stringify(params, {arrayFormat: 'repeat', encode: false})
            }
        })
            .catch(this.handleErrorWrapper)
    },

    post(path, data = {}, headers = {}, params = {}) {
        return Axios.post(`${path}`, data, {
            params: params,
            headers: headers
        })
            .catch(this.handleErrorWrapper);
    },

    put(path, data = {}, headers = {}, params = {}) {
        return Axios.put(`${path}`, data, {
            params: params,
            headers: headers
        })
            .catch(this.handleErrorWrapper)
    },

    delete(path, data) {
        return Axios.delete(`${path}`).catch(this.handleErrorWrapper)
    },

    handleErrorWrapper(error) {
        const {response} = error;
        throw {
            message: response.data.outcome || response.data.message || response.statusText,
            status: response.status,
            data: response.data.data
        }
    }
};


const authorizationServer = {

    get(path, params, headers) {
        return generic.get(`${path}`, params, headers);
    },

    post(path, data, headers) {
        return generic.post(`${path}`, data, headers);
    },

    put(path, data, headers) {
        return generic.put(`${path}`, data, headers);
    },

    delete(path, headers) {
        return generic.delete(`${path}`, headers);
    },

    requests: {}
};


const resourceServer = {

    get(path, params, headers) {
        return generic.get(`/api/${path}`, params, headers);
    },

    post(path, data, headers, params) {
        return generic.post(`/api/${path}`, data, headers, params);
    },

    put(path, data, headers, params) {
        return generic.put(`/api/${path}`, data, headers, params);
    },

    delete(path, headers) {
        return generic.delete(`/api/${path}`, headers);
    },

    requests: {
        admin: {}
    }
};


resourceServer.requests.getBusinessUser = () => resourceServer.get('user/business');

resourceServer.requests.updateUserLocale = () => resourceServer.put('user/locale');

resourceServer.requests.exchangeAutologinToken = (token) => {
    return resourceServer.post(`user/autologin`, {token});
};

resourceServer.requests.getUserAlerts = () => {
    return resourceServer.get(`user/alerts`);
};

resourceServer.requests.getUserSettings = () => {
    return resourceServer.get(`user/settings`);
};

resourceServer.requests.saveUserSettings = (params) => {
    return resourceServer.put(`user/settings`, params);
};


resourceServer.requests.getVisitsDatesForDoctorDashboardCalendar = () => {
    return resourceServer.get(`visit/doctor/dashboard/calendar`);
};

resourceServer.requests.sendRating = (token, score) => {
    return resourceServer.get(`patient-rating/`+token, {score: score});
};

resourceServer.requests.getVisitsForDoctorDashboardByDoctorIdAndDateAndType = (date, params) => {
    return resourceServer.get(`visit/doctor/dashboard/date/${date}`, params);
};

resourceServer.requests.cancelVisit = (visitId) => {
    return resourceServer.put(`visit/${visitId}/cancel`);
};

resourceServer.requests.setVisitToMove = (visitId) => {
    return resourceServer.put(`visit/${visitId}/to-move`);
};

resourceServer.requests.changeVisit = (visitSlots) => {
    return resourceServer.post(`visit/change`, visitSlots);
};

resourceServer.requests.getPatientSummaryById = (patientId) => {
    return resourceServer.get(`patient/${patientId}/summary`);
};

resourceServer.requests.getDoctorWorkPlan = (dateFrom, dateTo) => {
    return resourceServer.get(`doctor/workplan/all`, {dateFrom, dateTo});
};

resourceServer.requests.addDoctorVacation = (doctorId, body) => {
    return resourceServer.post(`doctor/${doctorId}/workplan/vacation`, body);
};

resourceServer.requests.deleteDoctorVacation = (doctorId, vacationId) => {
    return resourceServer.delete(`doctor/${doctorId}/workplan/vacation/${vacationId}`);
};

resourceServer.requests.saveDoctorWorkPlan = (body) => {
    return resourceServer.post(`doctor/workplan/base`, body);
};

resourceServer.requests.updateDoctorWorkPlan = (workPlanId, body) => {
    return resourceServer.put(`doctor/workplan/base/${workPlanId}`, body);
};

resourceServer.requests.deleteDoctorWorkPlan = (workPlanId) => {
    return resourceServer.delete(`doctor/workplan/base/${workPlanId}`);
};

resourceServer.requests.getAllWorkPlaces = () => {
    return resourceServer.get(`workplace/all`);
};

resourceServer.requests.getDoctorWorkPlace = () => {
    return resourceServer.get(`doctor/workplace`);
};

resourceServer.requests.getDoctorWorkPlaces = () => {
    return resourceServer.get(`doctor/workplaces`);
};

resourceServer.requests.getDoctorVacations = (dateFrom, dateTo) => {
    return resourceServer.get(`workplan/vacation`, {dateFrom: dateFrom, dateTo: dateTo});
};

resourceServer.requests.getCurrentDoctorMedicalServices = () => {
    return resourceServer.get(`doctor/medical-service`);
};

resourceServer.requests.getDoctorsVisits = (params) => {
    return resourceServer.get(`visit/doctor`, params);
};

resourceServer.requests.getDoctorsVisitsInfo = (patientId, mode, isConsultation, partnerId) => {
    return resourceServer.get(`doctor-pricelist/available/${patientId}`, {mode: mode, isConsultation: isConsultation, partnerId: partnerId || undefined});
};

resourceServer.requests.getAvailablePricelistForPatientFromReferral = (referralId) => {
    return resourceServer.get(`doctor-pricelist/referral/${referralId}`);
};

resourceServer.requests.getConsultationTypesForPatient = (patientId) => {
    return resourceServer.get(`doctor-pricelist/consultation/${patientId}`);
};

resourceServer.requests.getAvailableReferrals = () => {
    return resourceServer.get(`referral/available`);
};

resourceServer.requests.getDoctorVisitsSlots = (doctorPriceListItemId, dateFrom, days, selectedDuration, lookBack, workPlaceId, partnerId, visitId, visitPackageStageId, doctorPackageId) => {
    return resourceServer.get(`doctor/visit-times`, {
        doctorPriceListItemId, dateFrom, days, selectedDuration, lookBack, workPlaceId, partnerId, visitId, visitPackageStageId, doctorPackageId
    });
}

resourceServer.requests.getVisitsSlots = (patientId, medicalService, visitType, workPlaceId, dateFrom, days, lookBack, selectedDuration, partnerId, visitId, visitPackageStageId) => {
    return resourceServer.get(`doctor/patient/${patientId}/visit-times`, {
        medicalService, visitType, workPlaceId, dateFrom, days, lookBack, selectedDuration, partnerId, visitId, visitPackageStageId
    });
};

resourceServer.requests.getWidgetVisitsSlots = (patientType, medicalService, visitType, workPlaceId, dateFrom, days, lookBack, visitPackageStageId) => {
    return resourceServer.get(`appointing-widget/slot`, {
        patientType, medicalService, visitType, workPlaceId, dateFrom, days, lookBack, visitPackageStageId
    });
};

resourceServer.requests.getWidgetVisitsDoctorSlots = (doctorId, patientType, medicalService, visitType, workPlaceId, dateFrom, days, visitOrder, lookBack, visitPackageStageId) => {
    return resourceServer.get(`appointing-widget/slot/${doctorId}`, {
        patientType, medicalService, visitType, dateFrom, days, lookBack, workPlaceId, visitOrder, visitPackageStageId
    });
};

resourceServer.requests.appointVisit = (patientId, appointDto, referralId, installments, paymentProvider, paymentMarking, discountPrice, partnerId) => {
    return resourceServer.post(`visit/appoint/${patientId}`, appointDto);
};

resourceServer.requests.appointVisitPackage = (patientId, packageType, appointDto) => {
    return resourceServer.post(`visit/appoint/${patientId}/package/${packageType}`, appointDto);
};

resourceServer.requests.getDoctorsVisitsUnpaged = (params) => {
    return resourceServer.get(`visit/doctor`, {
        size: 2147483647, // Integer.MAX_VALUE
        ...params,
    }).then(x => x.content);
};

resourceServer.requests.getDoctorAccountGeneralInfo = () => {
    return resourceServer.get(`doctor/form/general-info`);
};

resourceServer.requests.saveDoctorAccountGeneralInfo = (params) => {
    return resourceServer.put(`doctor/form/general-info`, params);
};


resourceServer.requests.getDoctorAccountPractiseInfo = () => {
    return resourceServer.get(`doctor/form/practise-info`);
};

resourceServer.requests.saveDoctorAccountPractiseInfo = (params) => {
    return resourceServer.put(`doctor/form/practise-info`, ProcessingUtils.processJsonIntoFormData(params), {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.getProfessionsDictionary = () => {
    return resourceServer.get(`dictionary/profession`, {
        size: 2147483647
    });
};

resourceServer.requests.getScientificSocietiesDictionary = () => {
    return resourceServer.get(`dictionary/scientific-society`, {
        size: 2147483647
    });
};

resourceServer.requests.getIcd10Dictionary = () => {
    return resourceServer.get(`dictionary/icd10`);
};

resourceServer.requests.getIcd10BySearchPhrase = (phrase) => {
    return resourceServer.get(`dictionary/icd10/search`, {phrase});
};

resourceServer.requests.getDrugDictionary = () => {
    return resourceServer.get(`dictionary/drug`);
};

resourceServer.requests.getDrugsBySearchPhrase = (phrase, activeSubstance = null) => {
    return resourceServer.get(`dictionary/drug/search`, {phrase, activeSubstance});
};

resourceServer.requests.getActiveSubstancesBySearchPhrase = (phrase) => {
    return resourceServer.get(`dictionary/active-substances/search`, {phrase});
};

resourceServer.requests.getVisitConsultationByVisitPublicId = async (visitPublicId) => {
    return resourceServer.get(`consultation/${visitPublicId}`);
};

resourceServer.requests.saveVisitConsultation = async (visitId, params) => {
    return resourceServer.put(`consultation/${visitId}`, params);
};

resourceServer.requests.endVisitConsultation = async (visitId) => {
    return resourceServer.put(`visit/${visitId}/end`);
};

resourceServer.requests.getVisitSummaryPdf = async (visitId, visitTimeFrom, testRun) => {
    return Axios.get(`/api/visit/summary/pdf`, {
        params: {
            id: visitId,
            testRun: testRun
        },
        responseType: 'blob'
    })
        .then((result) => {
            saveAs(result, vue.$t('ui.visitSummary') + (visitTimeFrom ? moment(visitTimeFrom).format('_YYYY_MM_DD__HH_mm') : '') + '.pdf');
        })
};

resourceServer.requests.getCurrentDrugTherapy = () => {
    return resourceServer.get(`drug-therapy/current`);
};

resourceServer.requests.updateDrugUsage = (drugUsageUpdate) => {
    return resourceServer.post(`drug-therapy/usage`, drugUsageUpdate);
};

resourceServer.requests.getPatientTherapiesHistory = (patientId) => {
    return resourceServer.get(`drug-therapy/history/${patientId}`);
};

resourceServer.requests.postPatientMood = (mood) => {
    return resourceServer.post(`patient/mood`, mood);
};

resourceServer.requests.getCurrentPatientMood = () => {
    return resourceServer.get(`patient/mood`, {size: 1});
};

resourceServer.requests.getDoctorPlannedDutiesCount = (dateFrom, dateTo) => {
    return resourceServer.get(`duty/doctor/planned/count`,
        {
            dateFrom,
            dateTo,
        });
};

resourceServer.requests.getAllDoctorDuties = (dateFrom, dateTo) => {
    return resourceServer.get(`duty/doctor/all`,
        {
            dateFrom,
            dateTo
        });
};

resourceServer.requests.getDoctorDutiesUnpaged = (dateFrom, dateTo) => {
    return resourceServer.get(`duty/doctor`,
        {
            dateFrom,
            dateTo,
            size: 2147483647, // Integer.MAX_VALUE
        }).then(x => x.content);
};

resourceServer.requests.getAnonymizedDutiesUnpaged = (dateFrom, dateTo) => {
    return resourceServer.get(`duty/anonymized`, {
        dateFrom,
        dateTo,
        size: 2147483647, // Integer.MAX_VALUE
    }).then(x => x.content);
};

resourceServer.requests.addDuties = (duties) => {
    return resourceServer.post(`duty/doctor`, duties);
};

resourceServer.requests.updateDuty = (duty) => {
    return resourceServer.put(`duty/doctor`, duty);
};

resourceServer.requests.deleteDuty = (id) => {
    return resourceServer.delete(`duty/doctor/${id}`);
};

resourceServer.requests.getCurrentDrugStockStates = (patientId) => {
    return resourceServer.get(`drug-stock/current/${patientId}`);
};

resourceServer.requests.updateDrugStocks = (drugStocks) => {
    return resourceServer.post(`drug-stock`, drugStocks);
};

resourceServer.requests.recalculateDrugStocks = () => {
    return resourceServer.post(`drug-stock/recalculate`);
};

resourceServer.requests.getLastPatientRecommendation = (withDrugTherapy) => {
    return resourceServer.get(`visit/recommendation`, {withDrugTherapy: !!withDrugTherapy});
};

resourceServer.requests.getPatientsVisits = (params) => {
    return resourceServer.get(`visit/patient`, params);
};

resourceServer.requests.getPatientsVisitsWithConsultationData = (patientId, params) => {
    return resourceServer.get(`consultation/patient/${patientId}`, params);
};

resourceServer.requests.getNextPatientVisitsInfo = (params) => {
    return resourceServer.get(`visit/next`, params);
};

resourceServer.requests.getVisitBasicInfo = (visitId) => {
    return resourceServer.get(`visit/${visitId}/basic`);
};

resourceServer.requests.getVisitsByPaymentUid = (paymentUid) => {
    return resourceServer.get(`visit/payment/${paymentUid}`);
};

resourceServer.requests.getVisitById = (visitId) => {
    return resourceServer.get(`visit/${visitId}`);
}

resourceServer.requests.getVisitBasicInfoByPublicId = (visitPublicId) => {
    return resourceServer.get(`visit/public/${visitPublicId}/basic`);
};

resourceServer.requests.getPatientAccountInfo = () => {
    return resourceServer.get(`patient/form/info`);
};

resourceServer.requests.getPatientAccountInfoById = (patientId) => {
    return resourceServer.get(`patient/form/info/${patientId}`);
};

resourceServer.requests.savePatientAccountInfo = (params) => {
    return resourceServer.put(`patient/form/info`, params);
};

resourceServer.requests.sendPatientRegistrationForm = (data) => {
    return resourceServer.post(`user/register/patient/request`, ProcessingUtils.processJsonIntoFormData(data), {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.resendRegistrationEmail = (email) => {
    return resourceServer.post(`user/register/patient/resend/${email}`, null);
};

resourceServer.requests.getPatientsPayments = (params) => {
    return resourceServer.get(`payments/patient`, params);
};

resourceServer.requests.getInvoicePdf = async (invoiceId, isCorrection) => {
    return Axios.get(`/api/invoice/pdf`, {
        params: {
            id: invoiceId
        },
        responseType: 'blob'
    })
        .then((result) => {
            saveAs(result, vue.$t(isCorrection ? 'ui.correctionInvoice' : 'ui.invoice') + moment().format('_YYYY_MM_DD__HH_mm') + '.pdf');
        })
};

resourceServer.requests.getInvoicePdfByVisitId = async (visitId) => {
    return Axios.get(`/api/invoice/visit/pdf`, {
        params: {
            visitId
        },
        responseType: 'blob'
    })
        .then((result) => {
            saveAs(result, vue.$t('ui.invoice') + moment().format('_YYYY_MM_DD__HH_mm') + '.pdf');
        })
};

resourceServer.requests.payForVisit = (visitId, paymentProvider) => {
    return resourceServer.put(`visit/${visitId}/pay`, {}, {},{paymentProvider : paymentProvider});
};

resourceServer.requests.getPaymentStatusByUid = (uid) => {
    return resourceServer.get(`payments/${uid}/status`);
};

resourceServer.requests.getPaymentByUid = (uid) => {
    return resourceServer.get(`payments/${uid}`);
};

resourceServer.requests.getHealthSurveyStatus = (patientId) => {
    return resourceServer.get(`health-survey/status/${patientId}`);
};

resourceServer.requests.getPatientSeizureTypesStatus = (patientId, includeCorrectionInfo) => {
    return resourceServer.get(`health-survey/seizure-types/${patientId}`, {includeCorrectionInfo: includeCorrectionInfo});
};

resourceServer.requests.updatePatientSeizureType = (patientId, patientSeizureTypeId, seizureTypeId, comment) => {
    return resourceServer.put(`health-survey/seizure-types/${patientId}`, {
        id: patientSeizureTypeId, seizureTypeId, comment
    });
};

resourceServer.requests.getPsychomotorDevelopment = (patientId) => {
    return resourceServer.get(`health-survey/psychomotor/${patientId}`);
};

resourceServer.requests.savePsychomotorDevelopment = (psychomotorDevelopmentDto, patientId) => {
    return resourceServer.post(`health-survey/psychomotor/${patientId}`, psychomotorDevelopmentDto);
};

resourceServer.requests.getSeizuresCharacteristics = (patientId) => {
    return resourceServer.get(`health-survey/seizures-characteristics/${patientId}`);
};

resourceServer.requests.saveSeizuresCharacteristics = (seizuresCharacteristicsDto, patientId) => {
    return resourceServer.post(`health-survey/seizures-characteristics/${patientId}`, seizuresCharacteristicsDto);
};

resourceServer.requests.getTreatedBefore = (patientId) => {
    return resourceServer.get(`health-survey/drug-history/${patientId}/treated-before`);
};

resourceServer.requests.getDrugsHistory = (patientId) => {
    return resourceServer.get(`health-survey/drug-history/${patientId}`);
};

resourceServer.requests.saveDrugsHistory = (drugHistoryDtos, patientId) => {
    return resourceServer.post(`health-survey/drug-history/${patientId}`, drugHistoryDtos);
};

resourceServer.requests.deleteVnsImplant = (implantId, patientId) => {
    return resourceServer.delete(`health-survey/drug-history/${patientId}/implant/${implantId}`);
}

resourceServer.requests.getComorbidities = (patientId) => {
    return resourceServer.get(`health-survey/comorbidities/${patientId}`);
};

resourceServer.requests.saveComorbidities = (comorbiditiesDto, patientId) => {
    return resourceServer.post(`health-survey/comorbidities/${patientId}`, comorbiditiesDto);
};

resourceServer.requests.getPatientIcd10History = (patientId) => {
    return resourceServer.get(`consultation/icd10/history/${patientId}`);
};

resourceServer.requests.getLastConsultations = (patientId, params) => {
    return resourceServer.get(`consultation/history/${patientId}`, params);
};

resourceServer.requests.getPendingMessagesForDashboard = (params) => {
    return resourceServer.get(`message/pending/paged`, params);
};

resourceServer.requests.markMessageAsMarkedById = (messageId) => {
    return resourceServer.put(`message/${messageId}/mark`);
};

resourceServer.requests.markMessageAsReadById = (messageId, setTimeResponded) => {
    return resourceServer.put(`message/${messageId}/read`, undefined, undefined, { setTimeResponded: setTimeResponded === true });
};

resourceServer.requests.getContacts = (params) => {
    return resourceServer.get(`message/contacts`, params);
};

resourceServer.requests.getPatientMessageVisitsArchive = (patientId, params) => {
    return resourceServer.get(`message/contacts/${patientId}`, params);
};

resourceServer.requests.getMessages = (visitId) => {
    return resourceServer.get(`message/visit/${visitId}/messages`);
};

resourceServer.requests.getMessageVisitByVisitId = (publicVisitId) => {
    return resourceServer.get(`message/visit/${publicVisitId}`);
};

resourceServer.requests.getLastMessageVisitByPatientId = (patientId) => {
    return resourceServer.get(`message/patient/${patientId}/last`);
};

resourceServer.requests.readMessagesByVisitId = (visitId) => {
    return resourceServer.post(`message/read/visit/${visitId}`);
};

resourceServer.requests.sendMessage = (visitId, content) => {
    return resourceServer.post(`message/visit/${visitId}`, content, {
        "Content-Type": "text/plain"
    });
};

resourceServer.requests.hasNewPaidMessageVisits = () => {
    return resourceServer.get(`message/new/exists`);
};

resourceServer.requests.addMessageVisit = (pricelistItemId, referralId) => {
    return resourceServer.put(`message/visit/new/${pricelistItemId}`, undefined, undefined, { referralId: referralId || undefined });
};

resourceServer.requests.getMessageVisitContent = (visitId) => {
    return resourceServer.get(`message/visit/${visitId}/content`);
};

resourceServer.requests.saveMessageVisitContent = (visitId, content) => {
    return resourceServer.put(`message/visit/${visitId}/content`, content);
};

resourceServer.requests.getDoctorDatabaseData = (id) => {
    return resourceServer.get(`doctor/database/data`, {doctorId: id});
};

resourceServer.requests.sendContactForm = (data) => {
    return resourceServer.post(`contact`, ProcessingUtils.processJsonIntoFormData(data), {
        'Content-Type': 'multipart/form-data'
    });
};


resourceServer.requests.getMedicalDocumentation = (patientId, params) => {
    return resourceServer.get(`documents/medical/${patientId}/paged`, params);
};

resourceServer.requests.uploadMedicalDocumentation = (data, files, streaming) => {

    const formData = ProcessingUtils.processJsonIntoFormData(data);

    files.forEach(f => {
        formData.append('uploads', f);
    });

    const path = streaming ? 'streaming/document/upload' : 'documents/medical'
    return resourceServer.post(path, formData, {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.editMedicalDocument = (id, data) => {

    const formData = ProcessingUtils.processJsonIntoFormData(data);

    return resourceServer.put(`documents/medical/${id}`, formData, {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.deleteMedicalDocument = (id) => {
    return resourceServer.delete(`documents/medical/${id}`);
};

resourceServer.requests.getMedicalDocumentationDiskStats = () => {
    return resourceServer.get(`documents/medical/disk-stats`);
};

resourceServer.requests.getMedicalDocumentationFilesCount = (patientId) => {
    return resourceServer.get(`documents/medical/${patientId}/count`);
};

resourceServer.requests.addMedicalDocumentationDiskSpace = () => {
    return resourceServer.put(`documents/medical/disk-stats`);
};

resourceServer.requests.getMedicalDocumentFileContent = (id) => {
    return Axios.get(`/api/documents/medical/${id}/content`, {
        responseType: 'blob'
    });
};

resourceServer.requests.getDoctorNoteFileContent = (id) => {
    return Axios.get(`/api/note-file/doctor/${id}/content`, {
        responseType: 'blob'
    });
};

resourceServer.requests.getAdminNoteFileContent = (id) => {
    return Axios.get(`/api/note-file/admin/${id}/content`, {
        responseType: 'blob'
    });
};

resourceServer.requests.getEpicrisisFileContent = (id) => {
    return Axios.get(`/api/epicrisis/file/${id}/content`, {
        responseType: 'blob'
    });
};

resourceServer.requests.downloadMedicalDocumentFileContent = async (id, filename) => {
    return Axios.get(`/api/documents/medical/${id}/download`, {
        responseType: 'blob'
    })
        .then((result) => {
            saveAs(result, filename);
        })
};

resourceServer.requests.getDoctorFinances = (params) => {
    return resourceServer.get(`doctor/finances`, params);
};

resourceServer.requests.getFinanceReport = async (dateFrom, dateTo, yearMonth) => {
    return Axios.get(`/api/doctor/finances/report`, {
        params: {
            dateFrom, dateTo
        },
        responseType: 'blob'
    })
        .then((result) => {
            saveAs(result, yearMonth + '.pdf');
        })
};

resourceServer.requests.getPatientPersonalDataSummary = () => {
    return resourceServer.get(`patient/personal-data-summary`);
};

resourceServer.requests.getPatientDashboardAlerts = () => {
    return resourceServer.get(`patient/alerts`);
};

resourceServer.requests.getDoctorDashboardAlerts = () => {
    return resourceServer.get(`doctor/alerts`);
};

resourceServer.requests.registerDoctorSelectionRequest = (data) => {
    return resourceServer.post(`patient/select/doctor`, data);
};

resourceServer.requests.getDoctorSelectionRequestDetails = (requestId) => {
    return resourceServer.get(`doctor/select/request/${requestId}/details`);
};

resourceServer.requests.acceptDoctorSelectionRequest = (requestId, messageId) => {
    return resourceServer.put(`doctor/select/request/${requestId}/message/${messageId}/accept`);
};

resourceServer.requests.rejectDoctorSelectionRequest = (requestId, messageId, data) => {
    return resourceServer.put(`doctor/select/request/${requestId}/message/${messageId}/reject`, data);
};

resourceServer.requests.getIctalSeizuresTypes = () => {
    return resourceServer.get('ictal/classification/episode/seizures/types')
};

resourceServer.requests.getIctalClassificationFromVector = (classificationRequest) => {
    return resourceServer.post('ictal/classification/episode/classify/stateless/answers', classificationRequest)
};

resourceServer.requests.getIctalClassification = (patientId, seizureTypeId) => {
    return resourceServer.get(`ictal/classification/episode/classify/patient/${patientId}/seizure/${seizureTypeId}`)
};

resourceServer.requests.getIctalTherapyClassification = (epilepsyTypeId, therapyTypeId) => {
    return resourceServer.get('ictal/classification/therapy/case', {epilepsyTypeId, therapyTypeId});
};

resourceServer.requests.getIctalTherapyClassifications = (epilepsyTypeId) => {
    return resourceServer.get('ictal/classification/therapy/case/all', {epilepsyTypeId});
};


// /case/specific/{therapyTypeId}/patient/{patientUserId}
resourceServer.requests.getIctalTherapyClassificationForPatientWithSelectedTherapyType = (patientId, therapyTypeId, available = true, refunded = true, reproductiveAgeOptional = null) => {
    return resourceServer.get(`ictal/classification/therapy/case/specific/${therapyTypeId}/patient/${patientId}`, {
        available,
        refunded,
        reproductiveAgeOptional
    });
}

resourceServer.requests.getIctalTherapyClassificationForPatient = (patientId, available = true, refunded = true, reproductiveAgeOptional = null) => {
    return resourceServer.get(`ictal/classification/therapy/case/patient/${patientId}`, {
        available,
        refunded,
        reproductiveAgeOptional
    });
}

resourceServer.requests.getIctalTherapyClassification = (epilepsyTypesIds, therapyTypeId, usedDrugsIds, age, pregnant, somaticDiseases, sex) => {
    return resourceServer.get('ictal/classification/therapy/case/specific', {
        epilepsyTypesIds,
        therapyTypeId,
        usedDrugsIds,
        age,
        pregnant,
        somaticDiseases,
        sex
    });
};

resourceServer.requests.getIctalTherapyClassificationWithDescription = (epilepsyTypesIds, therapyTypeId, usedDrugsIds, age, pregnant, somaticDiseases, sex, countryCode, shouldBeAvailable, shouldBeRefunded) => {
    return resourceServer.get('ictal/classification/therapy/case/specific/description', {
        epilepsyTypesIds,
        therapyTypeId,
        usedDrugsIds,
        age,
        pregnant,
        somaticDiseases,
        sex,
        countryCode,
        shouldBeAvailable,
        shouldBeRefunded
    });
};

resourceServer.requests.getIctalTherapyClassificationWithDescription = (epilepsyTypesIds, therapyTypeId, usedDrugsIds, age, pregnant, somaticDiseases, sex) => {
    return resourceServer.get('ictal/classification/therapy/case/specific/description', {
        epilepsyTypesIds,
        therapyTypeId,
        usedDrugsIds,
        age,
        pregnant,
        somaticDiseases,
        sex
    });
};

resourceServer.requests.getIctalAllDrugs = () => {
    return resourceServer.get('ictal/drugs');
};

resourceServer.requests.deleteIctalDrug = (id) => {
    return resourceServer.delete(`ictal/drugs/${id}`);
};

resourceServer.requests.saveIctalDrug = (drug) => {
    return resourceServer.post(`ictal/drugs`, drug);
};

resourceServer.requests.getPatientsWithSeizuresCharacteristics = (searchPhrase, pageable) => {
    return resourceServer.get('/patient/seizuresCharacteristics', {
        page: pageable.page,
        size: pageable.size,
        searchString: searchPhrase
    });
};

resourceServer.requests.getAllPatientsFiltered = (pageable, searchPhrase) => {
    return resourceServer.get('/patient', {
        allPatients: true,
        page: pageable.page,
        size: pageable.size,
        searchString: searchPhrase
    });
};

resourceServer.requests.getIctalExperts = () => {
    return resourceServer.get('ictal/expert');
};

resourceServer.requests.saveIctalExpert = (expert) => {
    return resourceServer.post(`ictal/expert`, expert);
};

resourceServer.requests.deleteIctalExpert = (id) => {
    return resourceServer.delete(`ictal/expert/${id}`);
};


resourceServer.requests.getIctalRecommendationsData = () => {
    return resourceServer.get('ictal/recommendations-data');
};

resourceServer.requests.saveIctalRecommendationsVector = (data) => {
    return resourceServer.post(`ictal/recommendations-data/vector`, data);
};


resourceServer.requests.getIctalEDiagnosisData = () => {
    return resourceServer.get('ictal/ediagnosis-data');
};

resourceServer.requests.saveIctalEDiagnosisData = (data) => {
    return resourceServer.post(`ictal/ediagnosis-data`, data);
};


resourceServer.requests.getIctalEpilepsyTypes = () => {
    return resourceServer.get('ictal/epilepsy-type');
};

resourceServer.requests.saveIctalEpilepsyType = (epilepsyType) => {
    return resourceServer.post(`ictal/epilepsy-type`, epilepsyType);
};

resourceServer.requests.deleteIctalEpilepsyType = (id) => {
    return resourceServer.delete(`ictal/epilepsy-type/${id}`);
};


resourceServer.requests.getIctalCoefficients = () => {
    return resourceServer.get('ictal/coefficient');
};

resourceServer.requests.saveIctalCoefficients = (data) => {
    return resourceServer.post(`ictal/coefficient`, data);
};


resourceServer.requests.getVideoChatSessionToken = (visitId) => {
    return resourceServer.get(`video-chat/token/${visitId}`);
};

resourceServer.requests.getVideoChatMeeting = (visitId) => {
    return resourceServer.get(`video-chat/meeting/${visitId}`);
}

resourceServer.requests.getVideoChatAttendee = (visitId) => {
    return resourceServer.get(`video-chat/attendee/${visitId}`);
}

resourceServer.requests.deleteVideoChatSessionToken = (visitId) => {
    return resourceServer.delete(`video-chat/token/${visitId}`);
};

resourceServer.requests.getPricelistForMedicalService = (medicalService, referralId) => {
    return resourceServer.get(`doctor-pricelist/${medicalService}`, { referralId: referralId || undefined });
};

resourceServer.requests.getDoctorNotes = (patientId) => {
    return resourceServer.get(`doctor-note/${patientId}`);
};

resourceServer.requests.getDoctorNotesForAdmin = (params) => {
    return resourceServer.get(`doctor-note/admin`, params);
};

resourceServer.requests.getDoctorNotesCount = (patientId) => {
    return resourceServer.get(`doctor-note/${patientId}/count`);
};

resourceServer.requests.addDoctorNote = (patientId, contentDto, files) => {
    const formData = ProcessingUtils.processJsonIntoFormData(contentDto);

    if(files) {
        files.forEach(file => formData.append('newFiles', file));
    }
    return resourceServer.post(`doctor-note/${patientId}`, formData, {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.addAdminNote = (noteDto) => {
    return resourceServer.post('doctor-note/admin', noteDto);
};

resourceServer.requests.updateDoctorNote = (doctorNoteId, contentDto, files) => {
    const formData = ProcessingUtils.processJsonIntoFormData(contentDto);

    if(files) {
        files.forEach(file => formData.append('newFiles', file));
    }
    return resourceServer.put(`doctor-note/${doctorNoteId}`, formData, {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.deleteDoctorNote = (doctorNoteId) => {
    return resourceServer.delete(`doctor-note/${doctorNoteId}`);
};

resourceServer.requests.getDoctorNames = () => {
    return resourceServer.get(`doctor/name/all`);
};

resourceServer.requests.getPatientBasicDataByPublicId = (publicId) => {
    return resourceServer.get(`patient/public/${publicId}/basic`);
};

resourceServer.requests.getPatientSeizureTypes = () => {
    return resourceServer.get(`patient/seizure-types`);
};

resourceServer.requests.getPatientHealthPlan = () => {
    return resourceServer.get(`patient/health-plan`);
};

resourceServer.requests.getPatientPersonalInfo = (patientId) => {
    return resourceServer.get(`patient/${patientId}/personal-info`);
};

/* prescriptions */

resourceServer.requests.getPatientPrescriptions = (patientId, params) => {
    return resourceServer.get(`ehealth/prescription/patient/${patientId}`, params);
};

resourceServer.requests.setPrescriptionsRead = (ids) => {
    return resourceServer.post(`ehealth/prescription/patient/read`, ids);
};

resourceServer.requests.getPrescriptionPdf = async (id) => {
    return Axios.get(`/api/ehealth/prescription/${id}/pdf`, {
        responseType: 'blob'
    });
};

resourceServer.requests.getProductPackages = (productId) => {
    return resourceServer.get(`ehealth/product/packages`, {id: productId});
};

resourceServer.requests.uploadPrescriptionFile = (prescriptionId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return resourceServer.post(`ehealth/prescription/${prescriptionId}/file`, formData, {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.checkPeselInEwus = (pesel, forceCheck) => {
    return resourceServer.get(`ehealth/ewus`, {pesel, forceCheck});
};

resourceServer.requests.getPaymentProviders = () => {
    return resourceServer.get(`payments/provider/available`);
};

resourceServer.requests.requestPrescription = (data) => {
    return resourceServer.post(`medical-request/prescription`, data);
};

resourceServer.requests.getPrescriptionRequest = (data) => {
    return resourceServer.get(`medical-request/prescription`, data);
};

resourceServer.requests.getPrescriptionRequestDetails = (id) => {
    return resourceServer.get(`medical-request/prescription/${id}/details`);
};

resourceServer.requests.getPrescriptionVisitDetails = (visitId) => {
    return resourceServer.get(`medical-request/prescription/visit/${visitId}/details`);
};

resourceServer.requests.rejectPrescriptionMedicalRequest = (id) => {
    return resourceServer.delete(`medical-request/prescription/${id}`);
};

resourceServer.requests.getVisitChatMessages = (visitId) => {
    return resourceServer.get(`visit-chat/${visitId}`);
};

resourceServer.requests.sendVisitChatMessage = (visitId, content) => {
    return resourceServer.post(`visit-chat/${visitId}`, content, {
        "Content-Type": "text/plain"
    });
};


/* admin requests */

resourceServer.requests.admin.setEwusPassword = (password) => {
    return resourceServer.put(`admin/settings/ewus/password`, {password})
}

resourceServer.requests.admin.getDoctors = () => {
    return resourceServer.get(`admin/doctor`);
};

resourceServer.requests.admin.getAllDoctors = () => {
    return resourceServer.get(`admin/doctor`, {all: true});
};

resourceServer.requests.admin.activateDoctor = (doctorId) => {
    return resourceServer.post(`admin/doctor/${doctorId}/activate`);
};

resourceServer.requests.admin.deactivateDoctor = (doctorId) => {
    return resourceServer.post(`admin/doctor/${doctorId}/deactivate`);
};

resourceServer.requests.admin.getDoctorPriceListInfo = (doctorId) => {
    return resourceServer.get(`admin/doctor/pricelist/${doctorId}`);
};

resourceServer.requests.admin.getDoctorDetails = (doctorId) => {
    return resourceServer.get(`admin/doctor/details/${doctorId}`);
};

resourceServer.requests.admin.saveDoctorPriceListInfo = (doctorId, params) => {
    return resourceServer.put(`admin/doctor/pricelist/${doctorId}`, params);
};

resourceServer.requests.admin.getSystemPriceListInfo = () => {
    return resourceServer.get(`admin/doctor/pricelist-system`);
};

resourceServer.requests.admin.saveSystemPriceListInfo = (data) => {
    return resourceServer.put(`admin/doctor/pricelist-system`, data);
};

resourceServer.requests.admin.sendDoctorRegistrationForm = (data) => {
    return resourceServer.post(`admin/doctor/register`, ProcessingUtils.processJsonIntoFormData(data), {
        'Content-Type': 'multipart/form-data'
    });
};

resourceServer.requests.admin.getVisitsToMove = () => {
    return resourceServer.get(`admin/visit/to-move`);
};

resourceServer.requests.admin.cancelVisit = (visitId, fullRefundReason) => {
    return resourceServer.put(`admin/visit/cancel`, {visitId: visitId, fullRefundReason: fullRefundReason || null});
};

resourceServer.requests.admin.moveVisit = (visitSlots) => {
    return resourceServer.post(`admin/visit/change`, visitSlots);
};

resourceServer.requests.admin.setVisitToMove = (visitId) => {
    return resourceServer.put(`admin/visit/${visitId}/to-move`);
};

resourceServer.requests.admin.getDoctorsVisitsUnpaged = (params, doctorId) => {
    return resourceServer.get(`admin/visit/doctor/${doctorId}`, {
        size: 2147483647, // Integer.MAX_VALUE
        ...params,
    }).then(x => x.content);
};

resourceServer.requests.admin.getDoctorWorkPlaces = (doctorId) => {
    return resourceServer.get(`admin/workplan/workplace/${doctorId}`);
};

resourceServer.requests.admin.getAllDoctorWorkPlans = (doctorId) => {
    return resourceServer.get(`admin/workplan/${doctorId}/all`);
};

resourceServer.requests.admin.getDoctorWorkPlans = (dateFrom, dateTo, doctorId) => {
    return resourceServer.get(`admin/workplan/${doctorId}`, {
        dateFrom,
        dateTo
    });
};

resourceServer.requests.admin.getDoctorDuties = (dateFrom, dateTo, doctorId) => {
    return resourceServer.get(`admin/workplan/duty/${doctorId}`,
        {
            dateFrom,
            dateTo,
            size: 2147483647, // Integer.MAX_VALUE
        }).then(x => x.content);
};

resourceServer.requests.admin.deleteDoctorWorkPlan = (workPlanId) => {
    return resourceServer.delete(`admin/workplan/${workPlanId}`);
};

resourceServer.requests.admin.saveDoctorWorkPlan = (body, doctorId) => {
    return resourceServer.post(`admin/workplan/${doctorId}`, body);
};

resourceServer.requests.admin.updateDoctorWorkPlan = (workPlanId, body) => {
    return resourceServer.put(`admin/workplan/${workPlanId}`, body);
};

resourceServer.requests.admin.getDoctorVacations = (dateFrom, dateTo, doctorId) => {
    return resourceServer.get(`admin/workplan/vacation/doctor/${doctorId}`, {dateFrom: dateFrom, dateTo: dateTo});
};

resourceServer.requests.admin.deleteDoctorVacation = (doctorVacationId) => {
    return resourceServer.delete(`admin/workplan/vacation/${doctorVacationId}`);
};

resourceServer.requests.admin.addDoctorVacation = (doctorId, dto) => {
    return resourceServer.post(`admin/workplan/vacation/doctor/${doctorId}`, dto);
};

resourceServer.requests.admin.getAllDoctorDuties = (dateFrom, dateTo, doctorId) => {
    return resourceServer.get(`admin/duty/doctor/${doctorId}/all`,
        {
            dateFrom,
            dateTo
        });
};

resourceServer.requests.admin.addDuties = (duties, doctorId) => {
    return resourceServer.post(`admin/duty/doctor/${doctorId}`, duties);
};

resourceServer.requests.admin.updateDuty = (duty) => {
    return resourceServer.put(`admin/duty/doctor`, duty);
};

resourceServer.requests.admin.getAllWorkPlaces = () => {
    return resourceServer.get(`admin/work-place`);
};

resourceServer.requests.admin.getMedicalServicesProvided = (doctorId) => {
    return resourceServer.get(`admin/workplan/medical-service/${doctorId}`);
};

export default {
    authorizationServer, resourceServer
}
