/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LocalTime } from './local-time';
import { MedicalServiceBaseDto } from './medical-service-base-dto';

/**
 * 
 * @export
 * @interface DoctorWorkPlanChangeDto
 */
export interface DoctorWorkPlanChangeDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorWorkPlanChangeDto
     */
    'date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorWorkPlanChangeDto
     */
    'docplannerEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorWorkPlanChangeDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorWorkPlanChangeDto
     */
    'doctorPackageEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorWorkPlanChangeDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<MedicalServiceBaseDto>}
     * @memberof DoctorWorkPlanChangeDto
     */
    'medicalServices'?: Array<MedicalServiceBaseDto>;
    /**
     * 
     * @type {number}
     * @memberof DoctorWorkPlanChangeDto
     */
    'officeId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorWorkPlanChangeDto
     */
    'singleDoctorEnabled'?: boolean;
    /**
     * 
     * @type {LocalTime}
     * @memberof DoctorWorkPlanChangeDto
     */
    'timeFrom'?: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof DoctorWorkPlanChangeDto
     */
    'timeTo'?: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof DoctorWorkPlanChangeDto
     */
    'visitType'?: DoctorWorkPlanChangeDtoVisitTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DoctorWorkPlanChangeDto
     */
    'workPlaceId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorWorkPlanChangeDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


