/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ListResponseDtoListMedicalDocumentDto } from '../model';
// @ts-ignore
import { MedicalDocumentDiskStatsDto } from '../model';
// @ts-ignore
import { MedicalDocumentDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
/**
 * DocumentsApiControllerApi - axios parameter creator
 * @export
 */
export const DocumentsApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMedicalDocument', 'id', id)
            const localVarPath = `/api/documents/medical/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMedicalDocumentFileContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadMedicalDocumentFileContent', 'id', id)
            const localVarPath = `/api/documents/medical/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMedicalDocument: async (id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editMedicalDocument', 'id', id)
            const localVarPath = `/api/documents/medical/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (additionDate !== undefined) { 
                localVarFormParams.append('additionDate', additionDate as any);
            }
    
            if (documentName !== undefined) { 
                localVarFormParams.append('documentName', documentName as any);
            }
    
            if (documentType !== undefined) { 
                localVarFormParams.append('documentType', documentType as any);
            }
    
            if (fileContent !== undefined) { 
                localVarFormParams.append('fileContent', fileContent as any);
            }
    
            if (fileMimeType !== undefined) { 
                localVarFormParams.append('fileMimeType', fileMimeType as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
            if (fileSize !== undefined) { 
                localVarFormParams.append('fileSize', fileSize as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('id', id2 as any);
            }
    
            if (patientId !== undefined) { 
                localVarFormParams.append('patientId', patientId as any);
            }
    
            if (resultDate !== undefined) { 
                localVarFormParams.append('resultDate', resultDate as any);
            }
    
            if (uploadedById !== undefined) { 
                localVarFormParams.append('uploadedById', uploadedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentFileContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMedicalDocumentFileContent', 'id', id)
            const localVarPath = `/api/documents/medical/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentationDiskStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/documents/medical/disk-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsCount: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getMedicalDocumentsCount', 'patientId', patientId)
            const localVarPath = `/api/documents/medical/{patientId}/count`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsPaged: async (patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getMedicalDocumentsPaged', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMedicalDocumentsPaged', 'pageable', pageable)
            const localVarPath = `/api/documents/medical/{patientId}/paged`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nameSearchPhase !== undefined) {
                localVarQueryParameter['nameSearchPhase'] = nameSearchPhase;
            }

            if (documentTypes) {
                localVarQueryParameter['documentTypes'] = documentTypes;
            }

            if (resultDateFrom !== undefined) {
                localVarQueryParameter['resultDateFrom'] = (resultDateFrom as any instanceof Date) ?
                    (resultDateFrom as any).toISOString().substr(0,10) :
                    resultDateFrom;
            }

            if (resultDateTo !== undefined) {
                localVarQueryParameter['resultDateTo'] = (resultDateTo as any instanceof Date) ?
                    (resultDateTo as any).toISOString().substr(0,10) :
                    resultDateTo;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMedicalDocument: async (uploads: Array<any>, data?: MedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploads' is not null or undefined
            assertParamExists('saveMedicalDocument', 'uploads', uploads)
            const localVarPath = `/api/documents/medical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApiControllerApi - functional programming interface
 * @export
 */
export const DocumentsApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedicalDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedicalDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMedicalDocumentFileContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentFileContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentationDiskStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MedicalDocumentDiskStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentationDiskStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentsCount(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentsCount(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListMedicalDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMedicalDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMedicalDocument(uploads, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApiControllerApi - factory interface
 * @export
 */
export const DocumentsApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalDocument(id: number, options?: any): Promise<string> {
            return localVarFp.deleteMedicalDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMedicalDocumentFileContent(id: number, options?: any): Promise<any> {
            return localVarFp.downloadMedicalDocumentFileContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: any): Promise<string> {
            return localVarFp.editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentFileContent(id: number, options?: any): Promise<string> {
            return localVarFp.getMedicalDocumentFileContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentationDiskStats(options?: any): Promise<MedicalDocumentDiskStatsDto> {
            return localVarFp.getMedicalDocumentationDiskStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsCount(patientId: number, options?: any): Promise<number> {
            return localVarFp.getMedicalDocumentsCount(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: any): Promise<ListResponseDtoListMedicalDocumentDto> {
            return localVarFp.getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMedicalDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: any): Promise<string> {
            return localVarFp.saveMedicalDocument(uploads, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApiControllerApi - object-oriented interface
 * @export
 * @class DocumentsApiControllerApi
 * @extends {BaseAPI}
 */
export class DocumentsApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public deleteMedicalDocument(id: number, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).deleteMedicalDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public downloadMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).downloadMedicalDocumentFileContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [additionDate] 
     * @param {string} [documentName] 
     * @param {string} [documentType] 
     * @param {string} [fileContent] 
     * @param {string} [fileMimeType] 
     * @param {string} [fileName] 
     * @param {number} [fileSize] 
     * @param {number} [id2] 
     * @param {number} [patientId] 
     * @param {string} [resultDate] 
     * @param {number} [uploadedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public getMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).getMedicalDocumentFileContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public getMedicalDocumentationDiskStats(options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).getMedicalDocumentationDiskStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public getMedicalDocumentsCount(patientId: number, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).getMedicalDocumentsCount(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {string} [nameSearchPhase] 
     * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
     * @param {string} [resultDateFrom] 
     * @param {string} [resultDateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} uploads 
     * @param {MedicalDocumentDto} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiControllerApi
     */
    public saveMedicalDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig) {
        return DocumentsApiControllerApiFp(this.configuration).saveMedicalDocument(uploads, data, options).then((request) => request(this.axios, this.basePath));
    }
}
