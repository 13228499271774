/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoctorDashboardVisitDto
 */
export interface DoctorDashboardVisitDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorDashboardVisitDto
     */
    'additionalDoctorNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'doctorFirstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorDashboardVisitDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'doctorLastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDashboardVisitDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorDashboardVisitDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'medicalService'?: DoctorDashboardVisitDtoMedicalServiceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDashboardVisitDto
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'patientBirthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'patientFirstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorDashboardVisitDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'patientLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'patientLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'patientPublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'sourceVisitPackageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'timeTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDashboardVisitDto
     */
    'toMove'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'type'?: DoctorDashboardVisitDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorDashboardVisitDto
     */
    'visitPackageCode'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorDashboardVisitDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorDashboardVisitDtoTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


