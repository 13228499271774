/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CertificateDto } from './certificate-dto';
import { ConsultationIcd10Dto } from './consultation-icd10-dto';
import { DrugStockStateDto } from './drug-stock-state-dto';
import { DrugTherapyDto } from './drug-therapy-dto';
import { ICD10Dto } from './icd10-dto';
import { PatientPrescriptionDto } from './patient-prescription-dto';
import { ReferralDto } from './referral-dto';
import { SickLeaveDto } from './sick-leave-dto';

/**
 * 
 * @export
 * @interface VisitContentDto
 */
export interface VisitContentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitContentDto
     */
    'availableFreeReferrals'?: Array<VisitContentDtoAvailableFreeReferralsEnum>;
    /**
     * 
     * @type {Array<CertificateDto>}
     * @memberof VisitContentDto
     */
    'certificates'?: Array<CertificateDto>;
    /**
     * 
     * @type {Array<ConsultationIcd10Dto>}
     * @memberof VisitContentDto
     */
    'consultationIcd10s'?: Array<ConsultationIcd10Dto>;
    /**
     * 
     * @type {Array<DrugStockStateDto>}
     * @memberof VisitContentDto
     */
    'drugStocks'?: Array<DrugStockStateDto>;
    /**
     * 
     * @type {Array<DrugTherapyDto>}
     * @memberof VisitContentDto
     */
    'drugTherapies'?: Array<DrugTherapyDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VisitContentDto
     */
    'drugTherapiesChanged'?: boolean;
    /**
     * 
     * @type {ICD10Dto}
     * @memberof VisitContentDto
     */
    'mainIcd10'?: ICD10Dto;
    /**
     * 
     * @type {string}
     * @memberof VisitContentDto
     */
    'noteZeroObservations'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitContentDto
     */
    'observations'?: string;
    /**
     * 
     * @type {Array<PatientPrescriptionDto>}
     * @memberof VisitContentDto
     */
    'prescriptions'?: Array<PatientPrescriptionDto>;
    /**
     * 
     * @type {string}
     * @memberof VisitContentDto
     */
    'recommendations'?: string;
    /**
     * 
     * @type {Array<ReferralDto>}
     * @memberof VisitContentDto
     */
    'referrals'?: Array<ReferralDto>;
    /**
     * 
     * @type {Array<SickLeaveDto>}
     * @memberof VisitContentDto
     */
    'sickLeaves'?: Array<SickLeaveDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitContentDtoAvailableFreeReferralsEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}


