import Countries from "../countries/countries";

export default {
    SeizureTypeEnum: {
        IA: "Focal simple seizure",
        IB: "Focal complex seizure",
        IC: "Focal seizure unfolding into generalized",
        IIA: "Absence seizure",
        IIB: "Mioclonic seizure",
        IIC: "Tonic seizure",
        IID: "Clonic seizure",
        IIE: "Tonic-clonic seizure",
        IIF: "Atonic seizure",
        GEN: "Generated seizure",
        UNK: "Unknown seizure"
    },

    SeizureTypeBriefEnum: {
        IA: "Focal simple seizure",
        IB: "Focal complex seizure",
        IC: "Focal seizure unfolding into generalized",
        IIA: "Absence",
        IIB: "Mioclonic",
        IIC: "Tonic",
        IID: "Clonic",
        IIE: "Tonic-clonic",
        IIF: "Atonic",
        GEN: "Generated",
        UNK: "Unknown"
    },

    MedicalEventTypeEnum: {
        SEIZURE: 'Seizure',
        MENSTRUATION: 'Menstruation',
        INFECTION: 'Infection',
        SLEEP_DEPRIVATION: 'Sleep deprivation',
        FEVER: 'Fever',
        OTHER: 'Other',
        PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START: 'Start of pregnancy or pre-pregnancy period',
        PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END: 'End of pregnancy or pre-pregnancy period'
    },

    MessageTypeEnum: {
        EMAIL: 'email',
        SMS: 'sms',
        SYS: 'system',
        CHAT: 'chat'
    },

    MessageStatusEnum: {
        NEW: 'new',
        SENT: 'sent',
        READ: 'read'
        // ERROR: 'error'
    },

    MessageMarkerEnum: {
        MARKED: 'marked',
        NOT_MARKED: 'not marked'
    },

    MedicalServiceSystemItemEnum: {
        SOS_TELECONFERENCE: 'SOS Teleconference',
        SOS_CHAT: 'SOS Chat'
    },

    MedicalServiceGroupEnum: {
        VISIT_0: 'Survey 0',
        MIGRATION_VISIT: 'Migration visit',
        VISIT_1: 'Visit 1',
        STANDARD_VISIT: 'Standard visit',
        MESSAGE: 'Message',
        PRESCRIPTION: 'Prescription',
        NEUROSURGEON_CONSULTATION: 'Consultation - neurosurgeon',
        NEUROPSYCHOLOGIST_CONSULTATION: 'Consultation - neuropsychologist',
        NEUROLOGOPEDIST_CONSULTATION: 'Consultation - neurologopedist',
        PSYCHIATRIST_CONSULTATION: 'Consultation - psychiatrist',
        EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
        NEUROLOGIST_CONSULTATION: 'Consultation - neurologist',
        DIETICIAN_CONSULTATION: 'Consultation - dietician',
        VNS_CONSULTATION: 'VNS visit',
        GENETICIST_CONSULTATION: 'Consultation - geneticist',
        ABSENT_CONSULTATION: 'Absent consultation',
        METABOLIC_CONSULTATION: 'Metabolic consultation',
        EEG_CONSULTATION: 'EEG examination',
        SLEEP_DISTURBANCE_CONSULTATION: 'Sleep disturbance consultation',
        PARTNER_CONSULTATION: 'Partner consultation'
    },

    MedicalServiceGroupConsultantEnum: {
        NEUROSURGEON_CONSULTATION: 'neurosurgeon',
        NEUROPSYCHOLOGIST_CONSULTATION: 'neuropsychologist',
        NEUROLOGOPEDIST_CONSULTATION: 'neurologopedist',
        PSYCHIATRIST_CONSULTATION: 'psychiatrist',
        EPILEPTOLOGIST_CONSULTATION: 'epileptologist',
        NEUROLOGIST_CONSULTATION: 'neurologist',
        DIETICIAN_CONSULTATION: 'dietician',
        VNS_CONSULTATION: 'VNS consultant',
        GENETICIST_CONSULTATION: 'geneticist',
        METABOLIC_CONSULTATION: 'metabolic consultant',
        EEG_CONSULTATION: 'EEG/VEEG consultant',
        SLEEP_DISTURBANCE_CONSULTATION: 'sleep disturbance consultant'
    },

    MedicalServiceEnum: {
        VISIT_0: 'Survey 0',
        MIGRATION_VISIT: 'Migration visit',
        VISIT_1: 'Visit 1',
        STANDARD_VISIT: 'Standard visit',
        MESSAGE: 'Message',
        PRESCRIPTION: 'Prescription',
        NEUROSURGEON_CONSULTATION: 'Consultation - neurosurgeon',
        NEUROPSYCHOLOGIST_CONSULTATION: 'Consultation - neuropsychologist',
        NEUROLOGOPEDIST_CONSULTATION: 'Consultation - neurologopedist',
        PSYCHIATRIST_CONSULTATION: 'Consultation - psychiatrist',
        EPILEPTOLOGIST_CONSULTATION: 'Consultation - epileptologist',
        NEUROLOGIST_CONSULTATION: 'Consultation - neurologist',
        DIETICIAN_CONSULTATION: 'Consultation - dietician',
        VNS_CONSULTATION: 'VNS visit',
        GENETICIST_CONSULTATION: 'Consultation - geneticist',
        ABSENT_CONSULTATION: 'Absent consultation',
        METABOLIC_CONSULTATION: 'Metabolic consultation',
        CASE_CONFERENCE: 'Medical case conference',
        EEG_CONSULTATION: 'Standard EEG examination 30 min',
        VEEG_ON_STANDBY_CONSULTATION: 'VEEG examination in awake 1-6 h',
        VEEG_ON_STANDBY_CONSULTATION_1H: 'VEEG examination in awake 1 h',
        VEEG_ON_STANDBY_CONSULTATION_2H: 'VEEG examination in awake 2 h',
        VEEG_ON_STANDBY_CONSULTATION_6H: 'VEEG examination in awake 6 h',
        VEEG_DURING_SLEEP_CONSULTATION: 'VEEG examination during sleep  1-6 h',
        VEEG_DURING_SLEEP_CONSULTATION_1H: 'VEEG examination during sleep  1 h',
        VEEG_DURING_SLEEP_CONSULTATION_2H: 'VEEG examination during sleep  2 h',
        VEEG_DURING_SLEEP_CONSULTATION_6H: 'VEEG examination during sleep  6 h',
        VEEG_NIGHT_CONSULTATION: 'VEEG examination 12 h',
        VEEG_DAY_CONSULTATION: 'VEEG examination 24 h',
        SLEEP_DISTURBANCE_CONSULTATION: 'Sleep disturbance consultation'
    },

    VisitTypeEnum: {
        STATIONARY: 'Stationary',
        REMOTE: 'Remote',
        ABSENT: 'Absent'
    },

    VisitTypeDescriptionEnum: {
        STATIONARY: 'The visit takes place at an outpatient clinic in {place}. During the visit, the doctor meets the patient directly. On site, he reviews the patient\'s current documentation. If a prescription is issued - the doctor issues a prescription on the spot and sends it via SMS or prints it.',
        REMOTE: 'Online visit where the doctor and patient see each other on the screen. The connection is via Neuroterminal and does not require installation of additional software. The documentation is sent by the patient electronically via Neuroterminal. If a prescription is required, the doctor will issue an e-prescription.',
        ABSENT: 'An absent consultation is a type of medical visit carried out by a selected specialist without the presence of the patient. An absent consultation is aimed at analyzing medical records or an additional examination. The Neurosphery specialist will analyze the documentation, make an appropriate note and recommendations for the patient.'
    },

    AffinityTypeEnumAdults: {
        LEGAL_GUARDIAN: 'legal guardian',
        CURATOR: 'curator',
        SPOUSE: 'spouse'
    },

    AffinityTypeEnumChildren: {
        PARENT: 'parent',
        ADOPTER: 'adoter',
        GUARDIAN: 'appointed guardian'
    },

    VisitTypeEnumUndefined: 'Other',

    DocumentTypeEnum: {
        REFERRAL_GENERAL_PURPOSE: 'General referral',
        REFERRAL_CLINIC: 'Referral to a specialist clinic',
        REFERRAL_TO_HOSPITAL: 'Referral to the hospital',
        REFERRAL_TO_PSYCHIATRIC_HOSPITAL: 'Referral to a psychiatric hospital',
        REFERRAL_TO_SPECIALIST: 'Referral to specialist',
        REFERRAL_TO_LAB: 'Referral to the lab',
        REFERRAL_TO_DIAGNOSTIC_LABORATORY: 'Referral to diagnostic laboratory',
        CERTIFICATE: 'Certificate',
        CERTIFICATE_FOR_ZUS: 'Certificate for ZUS',
        CERTIFICATE_OF_DISABILITY: 'Certificate of disability',
        HEALTH_CERTIFICATE: 'Health certificate',
        INFORMATION_FOR_POZ_DOCTOR: 'Information for POZ doctor',
        APPLICATION_FOR_REFUND: 'Application for refund',
        APPLICATION_FOR_DRUG_REFUND: 'Application for drug refund',
        DEMAND_FOR_DRUG_FROM_ABROAD: 'Demand for a drug from abroad',
        DEMAND_FOR_FOODSTUFF_FROM_ABROAD: 'Demand for a foodstuff from abroad',
        OTHER: 'Other documents'
    },

    MedicalResultDocumentTypeEnum: {
        HOSPITAL_INFORMATION_CARD: 'Hospital treatment information card',
        DISEASE_HISTORY: 'Disease history',
        VIDEO_EVENT: 'Video - event',
        LABORATORY_TEST_RESULT: 'Laboratory test result',
        VISUAL_RESULT_MRI_FILE: 'Test result file - magnetic resonance',
        VISUAL_RESULT_MRI_DESC: 'Test result description - magnetic resonance',
        VISUAL_RESULT_CT_FILE: 'Test result file - computer tomography',
        VISUAL_RESULT_CT_DESC: 'Test result description - computer tomography',
        VISUAL_RESULT_EEG_FILE: 'Test result file - EEG',
        VISUAL_RESULT_EEG_DESC: 'Test result description - EEG',
        VISUAL_RESULT_ADDITIONAL_FILE: 'Additional test result file',
        VISUAL_RESULT_ADDITIONAL_DESC: 'Additional test result description',
        PATIENT_CERTIFICATE: 'Neurosphera certificate',
        STATE_CERTIFICATE: 'State certificate',
        EEG_DESCRIPTION: 'EEG description',
        OTHER: 'Other'
    },

    MedicalResultTypeEnum: {
        VIDEO_EVENT: 'Video',
        LABORATORY_TEST_RESULT: 'Laboratory test',
        VISUAL_RESULT_MRI_FILE: 'Magnetic resonance',
        VISUAL_RESULT_MRI_DESC: 'Magnetic resonance',
        VISUAL_RESULT_CT_FILE: 'Computer tomography',
        VISUAL_RESULT_CT_DESC: 'Computer tomography',
        VISUAL_RESULT_EEG_FILE: 'EEG',
        VISUAL_RESULT_EEG_DESC: 'EEG',
        VISUAL_RESULT_ADDITIONAL_FILE: 'Additional',
        VISUAL_RESULT_ADDITIONAL_DESC: 'Additional'
    },

    DoctorTitle: {
        LEK_MED: 'lek. med.',
        DR: 'dr',
        DR_MED: 'dr n. med.',
        DR_HAB: 'dr hab.',
        DR_PROF: 'prof dr hab.',
        TECHN: 'techn.',
        STUDENT: 'student UM',
        MGR: 'mgr'
    },

    PricelistItemGroupEnum: {
        NORMAL_VISIT: 'normal visit',
        SOS_VISIT: 'sos visit',
        DIAGNOSTIC: 'test description',
        OTHER: 'other'
    },

    PricelistItemTypeEnum: {
        FIRST_TIME: 'pierwszorazowa',
        SUBSEQUENT: 'kolejna',
        OTHER: 'inne'
    },

    SexEnum: {
        FEMALE: "Female",
        MALE: "Male"
    },

    SexEnumAbbr: {
        FEMALE: "F",
        MALE: "M"
    },

    SeizureFrequencyPeriodEnum: {
        YEAR: 'year',
        MONTH: 'month',
        DAY: 'day'
    },

    BloodType: {
        TYPE_A: "A",
        TYPE_B: "B",
        TYPE_AB: "AB",
        TYPE_0: "0"
    },

    RhType: {
        RH_PLUS: 'RH+',
        RH_MINUS: 'RH-'
    },

    PsychomotorDevelopmentOverallEnum: {
        NORMAL: 'Normal',
        RETARDED: 'Retarded',
        VERY_RETARDED: 'Very retarded',
        NOT_APPLICABLE: 'Not applicable'
    },

    PsychomotorRetardationEnum: {
        NO: 'No',
        LIGHT: 'Light',
        MODERATE: 'Moderate',
        SIGNIFICANT: 'Significant'
    },

    PsychomotorSpeechEnum: {
        NORMAL: 'Normal',
        DISTURBED: 'Disturbed',
        NOT_APPLICABLE: 'Not applicable'
    },

    PsychomotorWalkEnum: {
        NORMAL: 'Normal',
        INCORRECT: 'Incorrect',
        NOT_APPLICABLE: 'Not applicable'
    },

    PsychomotorSexEnum: {
        NORMAL: 'Normal',
        LIBIDO_DISORDERS: 'Libido disorders',
        NOT_APPLICABLE: 'Not applicable'
    },

    NystagmusEnum: {
        NO: 'No',
        VESTIBULAR: 'Vestibular',
        CNS: 'CNS'
    },

    VisionEnum: {
        NORMAL: 'Normal',
        DEFECT_PLUS: 'Vision defect plus (+)',
        DEFECT_MINUS: 'Vision defect minus (-)',
        DEFECT_OTHER: 'Other vision defect',
        BLIND: 'Blind',
        NOT_APPLICABLE: 'Not applicable'
    },

    HearingEnum: {
        NORMAL: 'Normal',
        DISTURBED: 'Disturbed',
        NOT_APPLICABLE: 'Not applicable'
    },

    SmokingEnum: {
        NO: 'No',
        YES_IN_PAST: 'Yes, in the past',
        YES_NOW: 'Yes, currently',
        NOT_APPLICABLE: 'Not applicable'
    },

    AlcoholEnum: {
        NO: 'No',
        SOMETIMES_LITTLE: 'Sometimes little',
        SOMETIMES_A_LOT: 'Sometimes a lot',
        OFTEN_LITTLE: 'Often little',
        OFTEN_A_LOT: 'Often a lot',
        NOT_APPLICABLE: 'Not applicable'
    },

    NarcoticsEnum: {
        NO: 'No',
        YES_IN_PAST: 'Yes, in the past',
        YES_NOW_RARELY: 'Yes, currently - occasionally',
        YES_NOW_OFTEN: 'Yes, obecnie - often',
        NOT_APPLICABLE: 'Not applicable'
    },

    SeizuresCharacteristicsFrequencyEnum: {
        ALWAYS: 'Yes (1 seizure) / Yes, always (100% seizures)',
        VERY_OFTEN: 'Yes, very often (75 - 99% of seizures)',
        OFTEN: 'Yes, often (51-74% of seizures)',
        SOMETIMES: 'Yes, sometimes (~ 50% of seizures)',
        RARELY: 'Yes, but rarely (25-49% of seizures)',
        VERY_RARELY: 'Yes, but extremely rare (1 - 24% of seizures)',
        NEVER: 'No (1 seizure) / No, never (0% seizures)',
        NOT_KNOWN: 'I do not know'
    },

    DrugHistoryTimeEnum: {
        PAST: 'Yes, in the past',
        CURRENTLY: 'Yes, currently'
    },

    DrugHistoryEffectEnum: {
        REMISSION: 'Remission',
        BIG_IMPROVEMENT: 'Improvement > 50%',
        LITTLE_IMPROVEMENT: 'Up to 50% improvement',
        NO_CHANGE: 'No change',
        LITTLE_DETERIORATION: 'Slight deterioration',
        BIG_DETERIORATION: 'Significant deterioration',
        EXTREME_DETERIORATION: 'Extreme deterioration / elimination side effects (e.g. rash)'
    },

    SeizuresCharacteristicsDurationEnum: {
        VERY_LONG: 'Very long, more than 5 minutes',
        LONG: 'Long, from 1 to 5 minutes',
        BIT_LONG: '30 to 60 seconds',
        VARIABLE: 'Completely different, changeable',
        SHORT: 'Briefly from a few to 30 seconds',
        VERY_SHORT: 'Very short, less than 5 sec',
        ULTRA_SHORT: 'Ultra-short, a fraction of a second to a second',
        NOT_KNOWN: 'I do not know'
    },

    UserAccountSettingCodeEnum: {
        VISIT_CANCELED_BY_DOCTOR: 'Alerts when doctor cancels a visit',
        VISIT_CANCELLED_BY_PATIENT: 'Alerts when patient cancels a visit',
        VISIT_CHANGED_BY_PATIENT: 'Alerts when patient changes a visit time',
        VISIT_APPOINTED_REMINDER: 'Reminders about visits appointed on next day',
        VISIT_PLANNED_REMINDER: 'Reminders about visits planned on next day',
        DUTY_PLANNED_REMINDER: 'Reminders about duties planned on next day',
        ADMIT_PATIENTS: 'Visible for patients in doctor database',
        MESSAGE_RECEIVED_BY_DOCTOR: 'Alerts when patient sends a message',
        PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR: 'Alerts when patient sends a prescription request'
    },

    VisitCancellationReason: {
        INCONVENIENT_DATE: 'Inconvenient date',
        DOCTOR_CHANGE: 'Doctor change'
    },

    DoctorTypeEnum: {
        RESIDENT: 'Clinical Resident',
        ASSISTANT: 'Assistant',
        SPECIALIST: 'Clinical Specialist',
        EXPERT: 'Medical Partner',
        CONSULTANT: 'Clinical Consultant',
        INTERNATIONAL_CONSULTANT: 'International Clinical Consultant',
        PARTNER_CONSULTANT: 'Partner Consultant',
    },

    PatientTypeEnum: {
        INFANT: 'children up to 2 years old',
        CHILD: 'children from 2 to 12 years old',
        YOUTH: 'youth from 12 to 18 years',
        ADULT: 'adults from 18 to 65 years',
        SENIOR: 'seniors over 65'
    },

    HasEpilepsyEnum: {
        NO: 'No',
        YES: 'Yes',
        NOT_KNOWN: 'Not known'
    },

    HealthSurveyPartEnum: {
        PSYCHOMOTOR_DEVELOPMENT: 'psychomotor development',
        SEIZURE_TYPES: 'seizure types',
        SEIZURES_HISTORY: 'seizures history',
        SEIZURES_CHARACTERISTICS: 'characteristics of seizures',
        COMORBIDITIES: 'comorbidities',
        DRUGS_HISTORY: 'treatment'
    },

    TimeRangeEnum: {
        '1M': '1 month',
        '3M': '3 months',
        '6M': '6 months',
        '1Y': '1 year',
        '2Y': '2 years',
        '3Y': '3 years',
        'SINCE_FIRST_SEIZURE': 'Since first seizure',
        'SINCE_BIRTH': 'Since birth',
        '1H': '1 hour',
        '2H': '2 hours',
        '3H': '3 hours',
        '4H': '4 hours',
        '5H': '5 hours',
        '6H': '6 hours'
    },

    ShortTimeRangeEnum: {
        'h': 'h',
        'm': 'min'
    },

    MedicalRequestStatusEnum: {
        'NEW': 'New',
        'ACCEPTED': 'Accepted',
        'DONE': 'Done',
        'REJECTED': 'Rejected',
        'CANCELLED': 'Canceled'
    },

    PersonalDocumentEnum: {
        'PASSPORT': 'Passport',
        'DRIVING_LICENSE': 'Driving license'
    },

    NSPHSourceEnum: {
        'INTERNET_SEARCH': 'Internet search',
        'SOCIAL_MEDIA': 'Social Media',
        'INTERNET_ARTICLE': 'Internet article',
        'PRESS_ARTICLE': 'Press article',
        'TV': 'Television',
        'FAMILY_FRIENDS': 'From family or friends',
        'DOCTOR': 'From doctor',
        'OTHER': 'Other'
    },

    NFZBranchEnum: {
        'NFZ_01': '01 - Lower Silesian Branch of the National Health Fund in Wrocław',
        'NFZ_02': '02 - Kujawsko-Pomorskie Branch of the National Health Fund in Bydgoszcz',
        'NFZ_03': '03 - Lublin Branch of the National Health Fund in Lublin',
        'NFZ_04': '04 - Lubuski Branch of the National Health Fund in Zielona Góra',
        'NFZ_05': '05 - Łódź Branch of the National Health Fund in Łódź',
        'NFZ_06': '06 - Małopolska Branch of the National Health Fund in Krakow',
        'NFZ_07': '07 - Mazowiecki Branch of the National Health Fund in Warsaw',
        'NFZ_08': '08 - Opole Branch of the National Health Fund in Opole',
        'NFZ_09': '09 - Podkarpackie Branch of the National Health Fund in Rzeszów',
        'NFZ_10': '10 - Podlaski Branch of the National Health Fund in Białystok',
        'NFZ_11': '11 - Pomeranian Branch of the National Health Fund in Gdańsk',
        'NFZ_12': '12 - Silesian Branch of the National Health Fund in Katowice',
        'NFZ_13': '13 - Świętokrzyski Branch of the National Health Fund in Kielce',
        'NFZ_14': '14 - Warmia and Mazury Branch of the National Health Fund in Olsztyn',
        'NFZ_15': '15 - Wielkopolska Branch of the National Health Fund in Poznań',
        'NFZ_16': '16 - West Pomeranian Branch of the National Health Fund in Szczecin'
    },

    VisitPackageEnum: {
        START_PACKAGE: "Survey 0 + Visit 1",
        ADHD_PACKAGE: "ADHD package",
        REGULAR_VISIT_EEG_PACKAGE: "Regular visit + EEG",
        MMPI2_PACKAGE: "MMPI-2 personality test",
        CBT_PACKAGE: "CBT therapy"
    },

    CountryEnum: Countries.en,

    WellBeingEnum: {
        WORST: "maximum worse / bad",
        MUCH_WORSE: "much worse",
        BIT_WORSE: "a bit worse",
        SAME: "same",
        BIT_BETTER: "a bit better",
        MUCH_BETTER: "much better",
        IDEAL: "perfect, full health"
    },
    CurrentTreatmentEnum: {
        WORST: "totally bad, it doesn't work and I don't want to continue it",
        VERY_BAD: "not good I prefer to change it",
        BAD: "I'm not well, but I can continue",
        NO_CHANGE: "I don't feel any change",
        BIT_BETTER: "it's ok, I feel a little better on this treatment",
        MUCH_BETTER: "there is an improvement, treatment is clearly helping",
        IDEAL: "the treatment is perfect, full success"
    },
    SideEffectsEnum: {
        WORST: "they are so serious that I do not want to continue this treatment for sure",
        BAD: "they are considerable and bother me, but if necessary I can continue",
        BEARABLE: "they are noticeable, but not that bad to change treatment",
        MINIMAL: "it's OK, side effects are minimal, no effect on me"
    },
    TreatmentEfficiencyEnum: {
        IDEAL: "Perfect",
        GOOD: "Good",
        MEDIOCRE: "Mediocre",
        BAD: "Bad",
        WORST: "Worst, need to change treatment"
    },
    TreatmentSafetyEnum: {
        TOTAL: "Total",
        ACCEPTABLE: "Acceptable",
        WEAK: "Weak",
        BAD: "Bad",
        WORST: "Rules out drug, necessary change"
    },
    AdditionalIncidentsEnum: {
        NEW_DISEASES: "New diseases",
        INJURIES: "Injuries",
        HOSPITALIZATIONS: "Hospitalization",
        OTHER: "Other",
        NONE: "None"
    },
    SeizureCountEnum: {
        MORE: "More",
        LESS: "Less",
        SAME: "Same",
        NONE: "None"
    },
    SeizureEvaluationEnum: {
        WORSE: "Worse",
        BETTER: "Lighter",
        NONE: "None"
    },
    AllSeizureRegisteredEnum: {
        TRUE: "I have registered all of them",
        FALSE: "I have not registered them - I will do it now"
    },
    ExternalReferralTypeEnum: {
        CLINIC: "to the specialist clinic",
        LABORATORY: "to the diagnostic laboratory",
        HOSPITAL: "for hospital treatment"
    },

    CertificateTypeEnum: {
        DRUGS_INTAKE: "A certificate to the health care center on the medications taken",
        POSTPONE_VACCINATION: "Certificate for postponing vaccination",
        NO_CONTRAINDICATION_FOR_VACCINE: "Certificate of no contraindications for vaccinations",
        NO_CONTRAINDICATION_FOR_PROCEDURE: "Certificate of no contraindications for procedure",
        NO_CONTRAINDICATION_FOR_ACTIVITIES: "Certificate of no contraindications to participate in the classes",
        NO_CONTRAINDICATION_FOR_WORK: "Certificate that there are no contraindications to perform phisical work",
        OTHER: "Other"
    },
    CertificateTypeDescriptionEnum: {
        DRUGS_INTAKE: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. I am asking for prescribing medications at the place of residence. The patient is taking the following medications",
        POSTPONE_VACCINATION: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. I am asking for postponement of vaccinations until",
        NO_CONTRAINDICATION_FOR_VACCINE: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. There are no contraindications for compulsory vaccinations",
        NO_CONTRAINDICATION_FOR_PROCEDURE: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. There are no contraindications for general anesthesia during the planned procedure",
        NO_CONTRAINDICATION_FOR_ACTIVITIES: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. There are no contraindications to participate in the activities",
        NO_CONTRAINDICATION_FOR_WORK: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre. There are no contraindications for physical work.",
        OTHER: "I hereby certify that the patient is under the care of the Neurosphera Neurology, Epilepsy and Psychiatry Centre."
    },

    MessageVisitSummaryStatusEnum: {
        NEW: "Unrealized",
        PENDING: "Awaiting reply",
        RESPONDED: "Replied",
        DONE: "Done",
        UNIDIRECTIONAL: "Unidirectional"
    },

    SickLeaveTypeEnum: {
        DISEASE: "Disease",
        CARE: "Caring for the sick"
    },

    SickLeaveIndicationEnum: {
        SHOULD_LIE: "Should lie",
        CAN_WALK: "Can walk"
    },

    SickLeaveInsuredInEnum: {
        ZUS: "ZUS",
        KRUS: "KRUS",
        OTHER_PL: "other in Poland",
        OTHER_COUNTRY: "in other country"
    },

    SickLeaveStatusEnum: {
        ISSUED: "Issued",
        CANCELED: "Canceled"
    },

    SurveyCompletedEnum: {
        YES: "Yes",
        NO: "No",
        PARTIALLY: "Half-completed"
    },

    EegCompletedEnum: {
        YES: "Yes",
        NO: "No",
        BEFORE_NEXT: "Will do before visit 1"
    },

    DocplannerIssueTypeEnum: {
        DP_VISIT_NOT_BOOKED: "Visit not booked in ZN",
        DP_VISIT_NOT_CANCELED: "Visit not canceled in ZN",
        DP_VISIT_NOT_MOVED: "Visit not moved in ZN",
        NOT_ELIGIBLE_FOR_CONSULTATION: "New patient - consultation"
    },

    DocplannerVisitStatusEnum: {
        NEW: "New",
        CANCELED_BY_SCHEDULER: "Not paid",
        CANCELED_BY_PERSON: "Canceled",
        SOLVED: "Solved",
        PAID_NOT_DONE: "Paid",
        DONE: "Done"
    },

    ReferralStatusEnum: {
        NEW: "New",
        CANCELLED: "Canceled",
        DONE: "Done"
    },

    VisitCaseSolvedEnum: {
        APPOINTMENT: "Appointment",
        RESIGNATION: "Resignation",
        OTHER: "Other"
    },

    VisitPaymentMarkingEnum: {
        WAITING_FOR_PAYMENT: "Marked for payment",
        PAYMENT_RECEIVED: "Marked as paid"
    },

    PaymentProviderEnum: {
        PAYU: 'PayU',
        PAYNOW: 'PayNow'
    },

    VisitPackageTemplateType: {
        EPILEPSY: 'Epilepsy',
        CONSULTATION: 'Consultation'
    },

    TherapyTypeEnum: {
        POLI: "Politherapy",
        MONO: "Monotherapy",
        NO_ACTIVE: 'Not active therapy'
    },

    ComorbidityTypeEnum: {
        HEADACHES: "Headaches",
        PSYCHIATRIC: "Psychiatric diseases",
        NEUROLOGICAL: "Neurological diseases",
        INFECTIOUS: "Inflammatory and infectious diseases",
        CANCER: "Cancer diseases",
        IMMUNE: "Blood and immune diseases",
        ENDOCRINE: "Endocrine and metabolic diseases",
        EYE: "Eye diseases",
        LARYNGOLOGICAL: "Laryngological diseases",
        HEART: "Diseases of the heart and circulatory system",
        RESPIRATORY: "Respiratory diseases",
        DIGESTIVE: "Digestive system diseases",
        SKIN: "Skin diseases",
        BONE: "Bone diseases",
        UROGENITAL: "Genitourinary diseases",
        DEVELOPMENTAL_DEFECTS: "Developmental, birth defects and chromosomal aberrations",
        INJURIES_POISONING: "Injuries and poisoning",
        SOMATIC: "Diseases that affect the treatment of epilepsy"
    },
    PatientAccountRemovalReason: {
        NO_MEDICAL_SERVICE: 'I have not found service I am interested in',
        NO_DATES: 'I have not found suitable visit date',
        PRICE: 'Service prices are not suitable for me',
        OTHER: 'Other'
    },

    PrescriptionRejectedReasonEnum: {
        VISIT_ONLY: "A prescription for a selected drug can only be issued during a visit",
        INQUIRY_REQUIRED: "A prescription for a selected drug may be issued after learning about the patient's current health condition",
        ALREADY_PRESCRIBED: "The previously issued prescription should meet the patient's need",
        OTHER: "Other"
    },

    BirthTypeEnum: {
        PHYSIOLOGICAL: "Physiological (forces of nature)",
        PLANNED_CESAREAN: "Caesarean section (planned)",
        UNPLANNED_CESAREAN: "Caesarean section (unplanned)",
        VACUUM: "Forces / vacuum"
    },

    PaymentRefundRestrictionsEnum: {
        REFUND_100: "more than 7 days before the service, 100% refund",
        REFUND_75: "75% refund 6-5 days before the service",
        REFUND_50: "50% refund 4-3 days before the service",
        REFUND_25: "25% refund 2 days before the service",
        REFUND_0: "the day before the service and on the day of the service without refund"
    },

    NeuroscreenDiagnosisEnum: {
        HEADACHES: "Headaches",
        MOVEMENT_ISSUES: "Movement disorders",
        MEMORY_ISSUES: "Memory disorders",
        EATING_ISSUES: "Eating disorders",
        CONSCIOUSNESS_ISSUES: "Disturbances of consciousness",
        PSYCHOMOTOR_DEVELOPMENT_ISSUES: "Psychomotor development disorders",
        EPILEPSY: "Epilepsy and seizures",
        SPINE_ISSUES: "Spine problems",
        MUTIPLE_SCLEROSIS: "Multiple sclerosis",
        DIZZINESS: "Vertigo",
        AUTISM: "Autism Spectrum",
        OTHER_SYMPTOMS: "Other neurological symptoms"
    },

    NeuroscreenFrequencyEnum: {
        ALWAYS: "Yes",
        SOMETIMES: "Sometimes",
        RARELY: "Rarely",
        NEVER: "Never"
    }
}
