export default {
  "user": {
    "notFound": "Benutzer nicht gefunden",
    "alreadyExists": "Der Benutzer mit dem angegebenen Login existiert bereits",
    "unauthorized": "Sie können diese Aktion nicht ausführen, da Sie keine Berechtigung haben",
    "emailAlreadyExists": "Diese E-Mail-Adresse ist bereits im System registriert"
  },
  "doctor": {
    "notFound": "Arzt nicht gefunden",
    "missingCertificate": "ZUS-Zertifikat fehlt",
    "priceList": {
      "notFound": "Preisliste des Arztes nicht gefunden",
      "wrongGrossPrice": "Falscher Bruttopreis ausgewählt",
      "grossPriceCalculation": "Falsche Berechnung des Besuchspreises",
      "notAvailable": "Der ausgewählte Dienst ist nicht verfügbar",
      "durationOutOfSync": "Die Dauer der Dienste des ausgewählten Typs sollte gleich sein"
    },
    "medicalService": {
      "notFound": "Medizinischer Dienst nicht gefunden"
    },
    "forms": {
      "nfzContract": {
        "numberDuplicated": "Arzt mit angegebener NFZ-Vertragsnummer existiert bereits"
      }
    },
    "certificate": {
      "numberInvalid": "Niepoprawny numer certyfikatu lekarza"
    },
    "selection": {
      "request": {
        "alreadyPending": "Eine Anfrage zum Wechsel des leitenden Arztes wurde bereits gesendet und wartet auf eine Antwort des Arztes",
        "incorrectStatus": "Ein Antrag auf Wechsel des leitenden Arztes hat einen falschen Status",
        "incorrectState": "Eine Anfrage zum Wechsel des leitenden Arztes hat einen falschen Status"
      }
    }
  },
  "patient": {
    "notFound": "Patient nicht gefunden",
    "mood": {
      "future": "Sie können keine Stimmung aus der Zukunft hinzufügen"
    }
  },
  "position": {
    "notFound": "Position nicht gefunden"
  },
  "visit": {
    "notFound": "Besuch nicht gefunden",
    "notDone": "Besuch nicht abgeschlossen",
    "notPaid": "Besuch nicht bezahlt",
    "edit": {
      "notAllowed": "Besuchsbearbeitung nicht zulässig"
    },
    "time": {
      "booked": "Datum aus der Vergangenheit ausgewählt"
    },
    "appointment": {
      "typeInvalid": "Ungültiger Termintyp"
    },
    "recommendations": {
      "empty": "Empfehlungen sollten vor Ende des Besuchs ausgefüllt werden"
    },
    "processing": {
      "error": "Beim Verarbeiten des Besuchs ist ein Fehler aufgetreten"
    },
    "summary": {
      "pdf": "Beim Generieren des PDF-Dokuments für den Besuch ist ein Fehler aufgetreten"
    },
    "invoice": {
      "pdf": "Beim Generieren der PDF-Rechnung für den Besuch ist ein Fehler aufgetreten"
    },
    "message": {
      "notInitialized": "Das Gespräch wurde vom Patienten noch nicht begonnen",
      "limitExceeded": "Das Nachrichtenlimit für einen Besuch ist ausgeschöpft",
      "notResponded": "Auf Ihre letzte Nachricht wurde noch nicht geantwortet"
    },
    "afterVisitZeroAvailable": "Die Funktionalität ist vor dem ersten Besuch nicht verfügbar"
  },
  "slot": {
    "stepNotAllowed": "Der ärztliche Dienst legt die Möglichkeit der Wahl der Besuchsdauer nicht fest",
    "timeNotInRange": "Die ausgewählte Besuchsdauer liegt nicht im möglichen Bereich",
    "timeIndivisibleByStep": "Die ausgewählte Besuchsdauer ist nicht durch den definierten Schritt teilbar"
  },
  "payment": {
    "receipt": {
      "pdf": "Beim Erstellen des Zahlungsbelegs ist ein Fehler aufgetreten"
    },
    "creation": "Beim Speichern der Zahlungsmethode ist ein Fehler aufgetreten",
    "visit": {
      "alreadyDone": "Besuch bereits bezahlt",
      "stillProcessing": "Der Besuch wird noch bearbeitet",
      "unknownState": "Besuchen Sie einen Zahlungsfehler. Bitte wenden Sie sich an das Neuroterminal-Team"
    },
    "alreadyDone": "Zahlung bereits abgeschlossen",
    "genericError": "Bei der Zahlungsabwicklung ist ein Fehler aufgetreten"
  },
  "date": {
    "selected": {
      "fromPast": "Besuchszeit gebucht"
    },
    "range": {
      "collision": "Ausgewählte Datumsbereiche stehen in Konflikt miteinander"
    }
  },
  "consultation": {
    "notFound": "Beratung nicht gefunden"
  },
  "nip": {
    "invalid": "Ungültiger NIP"
  },
  "medicalEvent": {
    "notFound": "Veranstaltung nicht gefunden",
    "invalidData": "Ungültige Ereignisdaten"
  },
  "medicalRequest": {
    "notFound": "Anfrage nicht gefunden",
    "invalidData": "Ungültige Anfrage",
    "alreadyAssigned": "Anfrage bereits zugewiesen"
  },
  "medicalResult": {
    "invalidData": "Ungültiges Ergebnis",
    "notFound": "Ergebnis nicht gefunden",
    "description": {
      "notFound": "Ergebnisbeschreibung nicht gefunden"
    },
    "file": {
      "invalidData": "Ungültige Ergebnisdateidaten"
    }
  },
  "medicalDocument": {
    "addError": "Beim Speichern des Dokuments ist ein Fehler aufgetreten",
    "editError": "Beim Bearbeiten des Dokuments ist ein Fehler aufgetreten",
    "downloadError": "Beim Herunterladen der Datei ist ein Fehler aufgetreten",
    "deleteError": "Beim Löschen des Dokuments ist ein Fehler aufgetreten",
    "file": {
      "saveError": "Beim Speichern der Datei ist ein Fehler aufgetreten"
    }
  },
  "drugStock": {
    "negativeAmount": "Der Betrag darf nicht kleiner als 0 sein",
    "notUnique": "Sie können das gleiche Produkt nicht mehrmals auswählen"
  },
  "product": {
    "notFound": "Produkt nicht gefunden",
    "notUnique": "Sie können nicht mehrmals dasselbe Produkt auswählen"
  },
  "patientSeizuresHistory": {
    "futureDateSelected": "Es wurde ein zukünftiges Datum ausgewählt",
    "periodConflict": "Ausgewählte Zeiträume stehen im Widerspruch zueinander"
  },
  "patientSeizureCharacteristics": {
    "notFound": "Die Anfallsgeschichte des Patienten war nicht vollständig abgeschlossen"
  },
  "healthSurvey": {
    "alreadyInitialized": "Ihre Krankengeschichte wurde bereits initialisiert",
    "patientHasNotEpilepsy": "Nur Patienten mit Epilepsie haben Zugang zur Umfrage 0"
  },
  "form": {
    "invalidEmail": "Ungültige E-Mail-Adresse",
    "invalidPhone": "Ungültige Telefonnummer"
  },
  "passwordReset": {
    "tokenIncorrect": "Der gesendete Link ist falsch. Das Abonnement ist abgelaufen",
    "internalError": "Fehler beim Verarbeiten der Anfrage zur Passwortänderung",
    "unsecure": "Das Passwort sollte mindestens 10 Zeichen, Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen enthalten"
  },
  "email": {
    "sendError": "Beim E-Mail-Versand ist ein Fehler aufgetreten"
  },
  "sms": {
    "noPhone": "Unbekannte Telefonnummer für den ausgewählten Benutzer"
  },
  "videochat": {
    "getTokenFailed": "Es ist ein Verbindungsfehler aufgetreten",
    "sessionExpired": "Besuchszeit abgelaufen. Verbindung kann nicht hergestellt werden."
  },
  "doctorNote": {
    "notFound": "Arztbrief nicht gefunden"
  },
  "referral": {
    "notFound": "Empfehlung nicht gefunden",
    "cancelFree": "Die Empfehlung für einen kostenlosen Besuch kann nicht storniert werden"
  },
  "harmonogram": {
    "notFound": "Der gewählte Besuchstermin ist bereits gebucht"
  },
  "unexpected": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später erneut",
  "incompleteData": "Die Daten sind unvollständig",
  "cered": {
    "prescription": {
      "creation": "Beim Ausstellen des Rezepts ist ein Fehler aufgetreten",
      "psychotropicNotUnique": "Das Rezept darf keine Kombination von Psychopharmaka mit anderen Arzneimitteln enthalten",
      "prescriptionTypeNotUnique": "Das Rezept darf keine Kombination aus RP- und RPW-Arzneimitteln enthalten",
      "prescriptionFileNotAvailable": "Ein Rezeptausdruck ist nicht verfügbar"
    },
    "ereferral": {
      "creation": "Beim Ausstellen der E-Überweisung ist ein Fehler aufgetreten"
    },
    "ewus": {
      "login": "Beim Anmelden bei EWUŚ ist ein Fehler aufgetreten",
      "check": "Bei der Überprüfung der Patientenversicherung in EWUŚ ist ein Fehler aufgetreten"
    },
    "zus": {
      "connect": "Beim Anmelden bei ZUS ist ein Fehler aufgetreten",
      "cannotCancel": "Dieser Krankenstand kann nicht storniert werden",
      "cannotFindWorkPlace": "Der NEUROSPHERA-Arbeitsplatz kann im ZUS-Konto nicht gefunden werden"
    }
  },
  "icd10": {
    "notUnique": "Sie können nicht mehrmals denselben ICD10 auswählen"
  },
  "visitPackage": {
    "dateCollision": "Pauschalbesuche können nicht am selben Tag vereinbart werden",
    "dateNotEqual": "Einige Besuche sollten noch am selben Tag stattfinden",
    "timeCollision": "Ausgewählte Besuchstermine stehen im Widerspruch zueinander",
    "wrongDuration": "Ungültige Besuchsdauer",
    "invalidService": "Einige ausgewählte Dienste sind in diesem Paket nicht verfügbar",
    "moveDateCollision": "Der neue Termin überschneidet sich mit einem anderen Termin im Paket",
    "someVisitsDone": "Besuche aus einem bereits begonnenen Paket können nicht storniert werden",
    "notActive": "Das Besuchspaket ist nicht verfügbar",
    "differentDoctor": "Einige der Besuche sollten beim gleichen Arzt erfolgen",
    "differentPlace": "Einige der Besuche sollten am selben Ort (oder online) stattfinden."
  },
  "request": {
    "blocked": {
      "reset": "Der Link zur Passwortänderung kann nicht gesendet werden, bevor seit der letzten Passwortänderungsanfrage {n} Stunden vergangen sind. Bitte wenden Sie sich an den Kundendienst.",
      "registration": "Der Link zur Passwortänderung kann nicht gesendet werden, bevor seit der letzten Passwortänderungsanfrage {n} Stunden vergangen sind. Bitte wenden Sie sich an den Kundendienst.",
      "notFound": "Das Zurücksetzen des Passworts kann nicht entsperrt werden, da die vorherige Anfrage nicht gefunden wurde.",
      "notActive": "Das Besuchspaket ist nicht verfügbar"
    }
  },
  "docplanner": {
    "issue": {
      "alreadyResolved": "Das Problem wurde bereits als gelöst markiert",
      "actionNotImplemented": "Für diesen Problemtyp ist keine automatische Aktion vorbereitet",
      "notFound": "Problem nicht gefunden"
    }
  },
  "hashtag": {
    "alreadyExists": "Hashtag mit dem angegebenen Namen existiert bereits"
  },
  "generic": {
    "alreadyExists": "Das Objekt mit der angegebenen Kennung existiert bereits"
  },
  "partner": {
    "notMatch": "Kein passender Dienst gefunden"
  }
}