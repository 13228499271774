import "roboto-fontface/css/roboto/roboto-fontface.css";

import "fontsource-baloo-paaji-2/400.css";
import "fontsource-baloo-paaji-2/500.css";
import "fontsource-baloo-paaji-2/600.css";
import "fontsource-baloo-paaji-2/700.css";
import "fontsource-baloo-paaji-2/800.css";

import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import vuetify from "./plugin/vuetify";

import App from "./App.vue";
import router from "./plugin/router";
import i18n from "./plugin/i18n";
import Enums from "./i18n/enums/enums";

import StorageService from "./service/storege.service";
import TimeUtils from "./utility/time.utils";
import DataStore from "@/utility/data.store";

import moment from "moment";
import "./plugin/masks";
import "./plugin/carousel";
import "./plugin/flags";
import "./plugin/keycloak";
import {
    AdminDocplannerControllerApi,
    AdminDoctorApiControllerApi, AdminDoctorPackageControllerApi,
    AdminFeatureControllerApi,
    AdminHashtagControllerApi,
    AdminMedicalRequestControllerApi,
    AdminMedicalServiceControllerApi,
    AdminMessageControllerApi,
    AdminNoteApiControllerApi,
    AdminPartnerControllerApi,
    AdminPatientApiControllerApi,
    AdminPatientManagementControllerApi,
    AdminPrescriptionControllerApi,
    AdminReferralControllerApi,
    AdminsManagementControllerApi, AdminUserControllerApi,
    AdminVisitApiControllerApi,
    AdminVisitPackageTemplateControllerApi,
    AdminWorkPlaceControllerApi,
    AdminWorkPlanApiControllerApi,
    AppointingWidgetControllerApi,
    CarePlanControllerApi,
    ChimeVideoControllerApi, ConsultationApiControllerApi,
    DictionariesApiControllerApi,
    DoctorApiControllerApi,
    DoctorNoteApiControllerApi, DoctorPackageControllerApi,
    DoctorPricelistApiControllerApi,
    DocumentsApiControllerApi,
    DrugTherapyApiControllerApi,
    EHealthApiControllerApi, EpicrisisControllerApi,
    ExternalReferralControllerApi, FeatureControllerApi,
    HealthStateSurveyApiControllerApi,
    IctalEpilepsyTypesControllerApi,
    IctalGtConfigurationControllerApi,
    IctalGtSeizureClassificationControllerApi,
    IctalTherapyClassificationControllerApi,
    InactivePatientControllerApi,
    MedicalEventApiControllerApi,
    MedicalHistoryControllerApi,
    MessageApiControllerApi, NeuroscreenControllerApi,
    NoteFileApiControllerApi, PartnerControllerApi,
    PatientAccountFormsApiControllerApi,
    PatientApiControllerApi,
    PatientCertificateApiControllerApi,
    PatientExaminationControllerApi,
    PatientHashtagControllerApi,
    PatientNoteApiControllerApi,
    ReferralApiControllerApi,
    SickLeaveControllerApi,
    StateCertificateControllerApi,
    SurveyZeroNoteControllerApi,
    SystemSettingsApiControllerApi,
    TestApiControllerApi,
    UserApiControllerApi,
    VisitApiControllerApi,
    VisitPackageControllerApi,
    VisitSurveyApiControllerApi
} from "@/api-client";

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import {TrackingService} from "@/service/TrackingService";

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
    vuetify,
});

export const instance = new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App),
    provide: {
        adminPatientControllerApi: new AdminPatientApiControllerApi(),
        adminWorkPlaceControllerApi: new AdminWorkPlaceControllerApi(),
        adminWorkPlanControllerApi: new AdminWorkPlanApiControllerApi(),
        adminMedicalServiceControllerApi: new AdminMedicalServiceControllerApi(),
        adminsManagementControllerApi: new AdminsManagementControllerApi(),
        doctorControllerApi: new DoctorApiControllerApi(),
        patientControllerApi: new PatientApiControllerApi(),
        doctorNoteControllerApi: new DoctorNoteApiControllerApi(),
        chimeVideoControllerApi: new ChimeVideoControllerApi(),
        adminNoteControllerApi: new AdminNoteApiControllerApi(),
        visitControllerApi: new VisitApiControllerApi(),
        doctorPricelistApiControllerApi: new DoctorPricelistApiControllerApi(),
        adminVisitApiControllerApi: new AdminVisitApiControllerApi(),
        patientAccountControllerApi: new PatientAccountFormsApiControllerApi(),
        ictalTherapyClassificationControllerApi: new IctalTherapyClassificationControllerApi(),
        appointingWidgetControllerApi: new AppointingWidgetControllerApi(),
        carePlanControllerApi: new CarePlanControllerApi(),
        referralControllerApi: new ReferralApiControllerApi(),
        medicalEventControllerApi: new MedicalEventApiControllerApi(),
        healthStateSurveyControllerApi: new HealthStateSurveyApiControllerApi(),
        visitSurveyControllerApi: new VisitSurveyApiControllerApi(),
        patientCertificateApiController: new PatientCertificateApiControllerApi(),
        externalReferralControllerApi: new ExternalReferralControllerApi(),
        eHealthControllerApi: new EHealthApiControllerApi(),
        dictionariesControllerApi: new DictionariesApiControllerApi(),
        drugTherapyControllerApi: new DrugTherapyApiControllerApi(),
        adminDoctorControllerApi: new AdminDoctorApiControllerApi(),
        adminMedicalRequestControllerApi: new AdminMedicalRequestControllerApi(),
        adminMessageControllerApi: new AdminMessageControllerApi(),
        patientNoteControllerApi: new PatientNoteApiControllerApi(),
        sickLeaveControllerApi: new SickLeaveControllerApi(),
        messageControllerApi: new MessageApiControllerApi(),
        adminReferralControllerApi: new AdminReferralControllerApi(),
        adminPrescriptionControllerApi: new AdminPrescriptionControllerApi(),
        surveyZeroNoteControllerApi: new SurveyZeroNoteControllerApi(),
        medicalHistoryControllerApi: new MedicalHistoryControllerApi(),
        adminPatientManagementControllerApi: new AdminPatientManagementControllerApi(),
        noteFileControllerApi: new NoteFileApiControllerApi(),
        testApiControllerApi: new TestApiControllerApi(),
        adminDocplannerControllerApi: new AdminDocplannerControllerApi(),
        systemSettingsApiControllerApi: new SystemSettingsApiControllerApi(),
        userApiControllerApi: new UserApiControllerApi(),
        ictalGtConfigurationControllerApi: new IctalGtConfigurationControllerApi(),
        ictalGtSeizureClassificationControllerApi: new IctalGtSeizureClassificationControllerApi(),
        adminHashtagControllerApi: new AdminHashtagControllerApi(),
        patientHashtagControllerApi: new PatientHashtagControllerApi(),
        adminPartnerControllerApi: new AdminPartnerControllerApi(),
        stateCertificateControllerApi: new StateCertificateControllerApi(),
        adminVisitPackageTemplateControllerApi: new AdminVisitPackageTemplateControllerApi(),
        visitPackageControllerApi: new VisitPackageControllerApi(),
        patientExaminationControllerApi: new PatientExaminationControllerApi(),
        documentsControllerApi: new DocumentsApiControllerApi(),
        ictalEpilepsyTypesControllerApi: new IctalEpilepsyTypesControllerApi(),
        inactivePatientControllerApi: new InactivePatientControllerApi(),
        adminFeatureControllerApi: new AdminFeatureControllerApi(),
        trackingService: new TrackingService(),
        consultationControllerApi: new ConsultationApiControllerApi(),
        adminDoctorPackageControllerApi: new AdminDoctorPackageControllerApi(),
        doctorPackageControllerApi: new DoctorPackageControllerApi(),
        adminUserControllerApi: new AdminUserControllerApi(),
        epicrisisControllerApi: new EpicrisisControllerApi(),
        neuroscreenControllerApi: new NeuroscreenControllerApi(),
        partnerControllerApi: new PartnerControllerApi(),
        featureControllerApi: new FeatureControllerApi()
    },
    methods: {
        changeLocale(locale) {
            StorageService.setInLocalStorage('locale', locale);
            this.$vuetify.lang.current = locale;
            this.$i18n.locale = locale;
            this.$i18n.localeConfig = this.$i18n.localeConfigs.find(l => l.code === locale);
            moment.locale(locale);
        },

        getLocale() {
            return this.$i18n.locale;
        },

        getLocaleConfig() {
            return this.$i18n.localeConfig;
        }
    },
    mounted() {
        let currentLocale = StorageService.getFromLocalStorage('locale');

        if (!currentLocale) {
            currentLocale = this.$i18n.fallbackLocale;
            StorageService.setInLocalStorage('locale', currentLocale);
        }

        this.$i18n.locale = currentLocale;
        this.$i18n.localeConfig = this.$i18n.localeConfigs.find(l => l.code === currentLocale);
        moment.locale(this.$i18n.locale);
    }
}).$mount('#app');


//GLOBAL FILTERS
Vue.filter('dateTime', TimeUtils.formatDateTime);

Vue.filter('date', (val, outputFormat, skipEnhancement) => TimeUtils.formatDateTime(val, outputFormat || 'DD.MM.YYYY', skipEnhancement));

Vue.filter('time', (val, inputFormat, outputFormat, skipEnhancement) => TimeUtils.formatTime(val, inputFormat, outputFormat, skipEnhancement));

Vue.filter('weekday', TimeUtils.getWeekday);

Vue.filter('timeSince', TimeUtils.getTimeSince);

Vue.filter('calendar', TimeUtils.calendar);

Vue.filter('duration', TimeUtils.humanize);

Vue.filter('durationHhMm', TimeUtils.durationHhMm);

Vue.filter('orDefault', (val, arg) => val || arg);

Vue.filter('age', val => instance.$i18n.tc('common.years', val, {n: val}));

Vue.filter('exactAge', val => {
    const exactAge = TimeUtils.getExactAge(val);
    if (exactAge.years > 0) {
        return instance.$i18n.tc('common.years', exactAge.years, {n: exactAge.years}) + (exactAge.months > 0 ? " " + instance.$i18n.tc('common.months', exactAge.months, {n: exactAge.months}) : "");
    } else if (exactAge.months > 0) {
        return instance.$i18n.tc('common.months', exactAge.months, {n: exactAge.months});
    } else {
        return instance.$i18n.tc('common.days', exactAge.days, {n: exactAge.days});
    }
});

Vue.filter('enumTranslation', (value, name, fallback) => {
    if (!value || !name) {
        return value;
    }
    const translation = Enums[instance.$i18n.locale][name][value];
    return translation || !fallback ? translation : fallback;
});

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('visitStatus', val => {
    if (val.done) {
        return instance.$i18n.t('common.visit.done');
    }
    if (val.cancelled) {
        return instance.$i18n.t('common.visit.cancelled');
    }
    if (val.toMove) {
        return instance.$i18n.t('common.visit.toMove');
    }
    if (val.paid) {
        return instance.$i18n.t('common.visit.paid');
    }
    if (!val.paid) {
        return instance.$i18n.t('common.visit.notPaid');
    }
    if (val.additional) {
        return instance.$i18n.t('common.visit.additional');
    }
});

Vue.filter('messageVisitStatus', val => {
    if (val.done) {
        return instance.$i18n.t('common.visit.done');
    }
    if (val.cancelled) {
        return instance.$i18n.t('common.visit.cancelled');
    }
    if (!val.paid) {
        return instance.$i18n.t('common.visit.notPaid');
    }
    if (val.paid) {
        return instance.$i18n.t('common.visit.messagePaid');
    }
});

Vue.filter('shorten', (text, arg) => {
    const maxLength = arg || 30;
    return text?.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
});

Vue.directive('has-feature', {
    bind: function (el, binding, vnode) {
        if (!DataStore.hasFeature(binding.value)) {
            const comment = document.createComment('')
            Object.defineProperty(comment, 'setAttribute', {
                value: () => undefined
            })
            vnode.elm = comment;

            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }
        }
    }
});