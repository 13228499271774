/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConsultationIcd10Dto } from '../model';
// @ts-ignore
import { PageConsultationBasicDto } from '../model';
// @ts-ignore
import { PagePatientVisitWithConsultationDataDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { VisitAdditionalDoctorContentDto } from '../model';
// @ts-ignore
import { VisitConsultationDto } from '../model';
// @ts-ignore
import { VisitContentDto } from '../model';
/**
 * ConsultationApiControllerApi - axios parameter creator
 * @export
 */
export const ConsultationApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastConsultations: async (patientId: number, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getLastConsultations', 'patientId', patientId)
            const localVarPath = `/api/consultation/history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIcd10History: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientIcd10History', 'patientId', patientId)
            const localVarPath = `/api/consultation/icd10/history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsVisitsWithConsultationData: async (patientId: number, pageable: Pageable, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientsVisitsWithConsultationData', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientsVisitsWithConsultationData', 'pageable', pageable)
            const localVarPath = `/api/consultation/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (visitTypes) {
                localVarQueryParameter['visitTypes'] = visitTypes;
            }

            if (visitServices) {
                localVarQueryParameter['visitServices'] = visitServices;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitAdditionalDoctorContent: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('getVisitAdditionalDoctorContent', 'publicId', publicId)
            const localVarPath = `/api/consultation/additional-observations/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitConsultationByVisitPublicId: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('getVisitConsultationByVisitPublicId', 'publicId', publicId)
            const localVarPath = `/api/consultation/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitAdditionalDoctorContentDto} visitAdditionalDoctorContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdditionalDoctorObservations: async (id: number, visitAdditionalDoctorContentDto: VisitAdditionalDoctorContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveAdditionalDoctorObservations', 'id', id)
            // verify required parameter 'visitAdditionalDoctorContentDto' is not null or undefined
            assertParamExists('saveAdditionalDoctorObservations', 'visitAdditionalDoctorContentDto', visitAdditionalDoctorContentDto)
            const localVarPath = `/api/consultation/additional-observations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitAdditionalDoctorContentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitContentDto} visitContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitConsultation: async (id: number, visitContentDto: VisitContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveVisitConsultation', 'id', id)
            // verify required parameter 'visitContentDto' is not null or undefined
            assertParamExists('saveVisitConsultation', 'visitContentDto', visitContentDto)
            const localVarPath = `/api/consultation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitContentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsultationApiControllerApi - functional programming interface
 * @export
 */
export const ConsultationApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsultationApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastConsultations(patientId: number, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageConsultationBasicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastConsultations(patientId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientIcd10History(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ConsultationIcd10Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientIcd10History(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsVisitsWithConsultationData(patientId: number, pageable: Pageable, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientVisitWithConsultationDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsVisitsWithConsultationData(patientId, pageable, visitTypes, visitServices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitAdditionalDoctorContent(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitAdditionalDoctorContentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitAdditionalDoctorContent(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitConsultationByVisitPublicId(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitConsultationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitConsultationByVisitPublicId(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitAdditionalDoctorContentDto} visitAdditionalDoctorContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAdditionalDoctorObservations(id: number, visitAdditionalDoctorContentDto: VisitAdditionalDoctorContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAdditionalDoctorObservations(id, visitAdditionalDoctorContentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitContentDto} visitContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVisitConsultation(id: number, visitContentDto: VisitContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVisitConsultation(id, visitContentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsultationApiControllerApi - factory interface
 * @export
 */
export const ConsultationApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsultationApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastConsultations(patientId: number, pageable?: Pageable, options?: any): Promise<PageConsultationBasicDto> {
            return localVarFp.getLastConsultations(patientId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientIcd10History(patientId: number, options?: any): Promise<Array<ConsultationIcd10Dto>> {
            return localVarFp.getPatientIcd10History(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsVisitsWithConsultationData(patientId: number, pageable: Pageable, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: any): Promise<PagePatientVisitWithConsultationDataDto> {
            return localVarFp.getPatientsVisitsWithConsultationData(patientId, pageable, visitTypes, visitServices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitAdditionalDoctorContent(publicId: string, options?: any): Promise<VisitAdditionalDoctorContentDto> {
            return localVarFp.getVisitAdditionalDoctorContent(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitConsultationByVisitPublicId(publicId: string, options?: any): Promise<VisitConsultationDto> {
            return localVarFp.getVisitConsultationByVisitPublicId(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitAdditionalDoctorContentDto} visitAdditionalDoctorContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdditionalDoctorObservations(id: number, visitAdditionalDoctorContentDto: VisitAdditionalDoctorContentDto, options?: any): Promise<void> {
            return localVarFp.saveAdditionalDoctorObservations(id, visitAdditionalDoctorContentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitContentDto} visitContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitConsultation(id: number, visitContentDto: VisitContentDto, options?: any): Promise<void> {
            return localVarFp.saveVisitConsultation(id, visitContentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsultationApiControllerApi - object-oriented interface
 * @export
 * @class ConsultationApiControllerApi
 * @extends {BaseAPI}
 */
export class ConsultationApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} [pageable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public getLastConsultations(patientId: number, pageable?: Pageable, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).getLastConsultations(patientId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public getPatientIcd10History(patientId: number, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).getPatientIcd10History(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
     * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public getPatientsVisitsWithConsultationData(patientId: number, pageable: Pageable, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).getPatientsVisitsWithConsultationData(patientId, pageable, visitTypes, visitServices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public getVisitAdditionalDoctorContent(publicId: string, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).getVisitAdditionalDoctorContent(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public getVisitConsultationByVisitPublicId(publicId: string, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).getVisitConsultationByVisitPublicId(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VisitAdditionalDoctorContentDto} visitAdditionalDoctorContentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public saveAdditionalDoctorObservations(id: number, visitAdditionalDoctorContentDto: VisitAdditionalDoctorContentDto, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).saveAdditionalDoctorObservations(id, visitAdditionalDoctorContentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VisitContentDto} visitContentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationApiControllerApi
     */
    public saveVisitConsultation(id: number, visitContentDto: VisitContentDto, options?: AxiosRequestConfig) {
        return ConsultationApiControllerApiFp(this.configuration).saveVisitConsultation(id, visitContentDto, options).then((request) => request(this.axios, this.basePath));
    }
}
