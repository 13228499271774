export default {
  "user": {
    "notFound": "Felhasználó nem található",
    "alreadyExists": "A megadott bejelentkezési névvel már létezik felhasználó",
    "unauthorized": "Nem hajthatja végre ezt a műveletet, mert nincs engedélye",
    "emailAlreadyExists": "Ez az e-mail cím már regisztrálva van a rendszerben"
  },
  "doctor": {
    "notFound": "Orvost nem találtak",
    "missingCertificate": "A ZUS tanúsítvány hiányzik",
    "priceList": {
      "notFound": "Az orvosi árlista nem található",
      "wrongGrossPrice": "Rossz bruttó ár lett kiválasztva",
      "grossPriceCalculation": "A látogatás árának hibás kiszámítása",
      "notAvailable": "A kiválasztott szolgáltatás nem elérhető",
      "durationOutOfSync": "A kiválasztott típusú szolgáltatások időtartamának meg kell egyeznie"
    },
    "medicalService": {
      "notFound": "Orvosi szolgáltatás nem található"
    },
    "forms": {
      "nfzContract": {
        "numberDuplicated": "A megadott NFZ-szerződésszámmal rendelkező orvos már létezik"
      }
    },
    "certificate": {
      "numberInvalid": "Niepoprawny numer certyfikatu lekarza"
    },
    "selection": {
      "request": {
        "alreadyPending": "Vezetőorvos-váltási kérelmet már elküldtek, és az orvos válaszára vár",
        "incorrectStatus": "A vezető orvos megváltoztatására vonatkozó kérelem állapota nem megfelelő",
        "incorrectState": "A vezető orvos cseréjére vonatkozó kérelem hibás állapotban van"
      }
    }
  },
  "patient": {
    "notFound": "A páciens nem található",
    "mood": {
      "future": "Nem adhat hozzá hangulatot a jövőből"
    }
  },
  "position": {
    "notFound": "A pozíció nem található"
  },
  "visit": {
    "notFound": "A látogatás nem található",
    "notDone": "A látogatás nem történt meg",
    "notPaid": "A látogatás nem fizetett",
    "edit": {
      "notAllowed": "Látogasson el a szerkesztés nem engedélyezett"
    },
    "time": {
      "booked": "Dátum a múltból kiválasztva"
    },
    "appointment": {
      "typeInvalid": "Érvénytelen találkozótípus"
    },
    "recommendations": {
      "empty": "Az ajánlásokat a látogatás befejezése előtt kell kitölteni"
    },
    "processing": {
      "error": "Hiba történt a látogatás feldolgozása közben"
    },
    "summary": {
      "pdf": "Hiba történt a látogatáshoz szükséges pdf-dokumentum generálásakor"
    },
    "invoice": {
      "pdf": "Hiba történt a látogatáshoz szükséges pdf-számla generálásakor"
    },
    "message": {
      "notInitialized": "A páciens még nem indította el a beszélgetést",
      "limitExceeded": "Az egy látogatásra vonatkozó üzenetkorlát kimerült",
      "notResponded": "Az utolsó üzenetedre még nem érkezett válasz"
    },
    "afterVisitZeroAvailable": "A funkció az első látogatás előtt nem elérhető"
  },
  "slot": {
    "stepNotAllowed": "Az orvosi szolgálat nem határozza meg a látogatás időtartamának megválasztásának lehetőségét",
    "timeNotInRange": "A kiválasztott látogatás hossza nem esik a lehetséges tartományba",
    "timeIndivisibleByStep": "A kiválasztott látogatás hossza nem osztható a meghatározott lépéssel"
  },
  "payment": {
    "receipt": {
      "pdf": "Hiba történt a fizetési nyugta generálásakor"
    },
    "creation": "Hiba történt a fizetési mód mentése közben",
    "visit": {
      "alreadyDone": "A látogatás már ki van fizetve",
      "stillProcessing": "A látogatás feldolgozása még folyamatban van",
      "unknownState": "Látogatás fizetési hiba, kérjük lépjen kapcsolatba a Neuroterminal csapatával"
    },
    "alreadyDone": "A fizetés már befejeződött",
    "genericError": "Hiba történt a fizetés feldolgozása közben"
  },
  "date": {
    "selected": {
      "fromPast": "Látogatás időpontja lefoglalva"
    },
    "range": {
      "collision": "A kiválasztott dátumtartományok ütköznek egymással"
    }
  },
  "consultation": {
    "notFound": "A konzultáció nem található"
  },
  "nip": {
    "invalid": "Érvénytelen NIP"
  },
  "medicalEvent": {
    "notFound": "Esemény nem található",
    "invalidData": "Érvénytelen eseményadatok"
  },
  "medicalRequest": {
    "notFound": "A kérés nem található",
    "invalidData": "Érvénytelen kérelem",
    "alreadyAssigned": "A kérés már hozzá van rendelve"
  },
  "medicalResult": {
    "invalidData": "Érvénytelen eredmény",
    "notFound": "Az eredmény nem található",
    "description": {
      "notFound": "Az eredmény leírása nem található"
    },
    "file": {
      "invalidData": "Érvénytelen eredményfájladat"
    }
  },
  "medicalDocument": {
    "addError": "Hiba történt a dokumentum mentése közben",
    "editError": "Hiba történt a dokumentum szerkesztése közben",
    "downloadError": "Hiba történt a fájl letöltése közben",
    "deleteError": "Hiba történt a dokumentum törlése közben",
    "file": {
      "saveError": "Hiba történt a fájl mentése közben"
    }
  },
  "drugStock": {
    "negativeAmount": "Az összeg nem lehet kisebb 0-nál",
    "notUnique": "Nem választhatja ki többször ugyanazt a terméket"
  },
  "product": {
    "notFound": "A termék nem található",
    "notUnique": "Nem választhatja ki többször ugyanazt a terméket"
  },
  "patientSeizuresHistory": {
    "futureDateSelected": "A jövő dátuma kiválasztott",
    "periodConflict": "A kiválasztott időszakok ütköznek egymással"
  },
  "patientSeizureCharacteristics": {
    "notFound": "A beteg rohamtörténetét nem fejezték be teljesen"
  },
  "healthSurvey": {
    "alreadyInitialized": "Az Ön kórtörténetét már inicializálták",
    "patientHasNotEpilepsy": "Csak az epilepsziás betegek férhetnek hozzá a 0. felméréshez"
  },
  "form": {
    "invalidEmail": "Érvénytelen e-mail cím",
    "invalidPhone": "Érvénytelen telefonszám"
  },
  "passwordReset": {
    "tokenIncorrect": "Az elküldött link hibás, lub lejárt",
    "internalError": "Hiba történt a jelszómódosítási kérelem feldolgozása közben",
    "unsecure": "A jelszónak legalább 10 karakterből, kis- és nagybetűből, számokból és speciális karakterekből kell állnia"
  },
  "email": {
    "sendError": "E-mail küldési hiba történt"
  },
  "sms": {
    "noPhone": "Ismeretlen telefonszám a kiválasztott felhasználóhoz"
  },
  "videochat": {
    "getTokenFailed": "Csatlakozási hiba történt",
    "sessionExpired": "A látogatási idő lejárt. A kapcsolat nem hozható létre."
  },
  "doctorNote": {
    "notFound": "Az orvosi jegyzet nem található"
  },
  "referral": {
    "notFound": "A hivatkozás nem található",
    "cancelFree": "Az ingyenes látogatásra vonatkozó ajánlást nem lehet lemondani"
  },
  "harmonogram": {
    "notFound": "A látogatás kiválasztott időpontja már le van foglalva"
  },
  "unexpected": "Váratlan hiba történt. Próbáld újra később",
  "incompleteData": "Az adatok hiányosak",
  "cered": {
    "prescription": {
      "creation": "Hiba történt a recept kiadása közben",
      "psychotropicNotUnique": "A vényköteles gyógyszer nem tartalmazhat pszichotróp gyógyszereket más gyógyszerekkel kombinációban",
      "prescriptionTypeNotUnique": "A recept nem tartalmazhatja az RP i RPW gyógyszerek kombinációját",
      "prescriptionFileNotAvailable": "Vényköteles nyomtatvány nem elérhető"
    },
    "ereferral": {
      "creation": "Hiba történt az e-utalvány kiadásakor"
    },
    "ewus": {
      "login": "Hiba történt az EWUŚ-ba való bejelentkezés során",
      "check": "Hiba történt az EWUŚ betegbiztosításának ellenőrzése során"
    },
    "zus": {
      "connect": "Hiba történt a ZUS-ba való bejelentkezés során",
      "cannotCancel": "Ezt a betegszabadságot nem lehet lemondani",
      "cannotFindWorkPlace": "A NEUROSPHERA munkahely nem található a ZUS fiókban"
    }
  },
  "icd10": {
    "notUnique": "Nem választhatja ki többször ugyanazt az icd10-et"
  },
  "visitPackage": {
    "dateCollision": "Csomaglátogatások nem egyeztethetők össze ugyanazon a napon",
    "invalidService": "A kiválasztott szolgáltatások egy része nem érhető el ebben a csomagban",
    "moveDateCollision": "Az új találkozó átfedésben van a csomagban szereplő másik találkozóval",
    "someVisitsDone": "A már megkezdett csomagból érkező látogatások nem mondhatók le",
    "dateNotEqual": "Néhány látogatásnak ugyanazon a napon kell megtörténnie",
    "timeCollision": "A látogatások kiválasztott dátumai ütköznek egymással",
    "wrongDuration": "Érvénytelen látogatás időtartama",
    "notActive": "Látogatási csomag nem elérhető",
    "differentDoctor": "A látogatások egy részét ugyanannál az orvosnál kell elvégezni",
    "differentPlace": "A látogatások egy részének ugyanazon a helyen (vagy online) kell történnie."
  },
  "request": {
    "blocked": {
      "reset": "A jelszómódosítási linket nem lehet elküldeni {n} óra elteltével az utolsó jelszómódosítási kérelem óta. Kérjük, forduljon az ügyfélszolgálathoz.",
      "registration": "A jelszómódosítási linket nem lehet elküldeni {n} óra elteltével az utolsó jelszómódosítási kérelem óta. Kérjük, forduljon az ügyfélszolgálathoz.",
      "notFound": "A jelszó visszaállítása nem oldható fel, mert az előző kérés nem található.",
      "notActive": "Látogatási csomag nem elérhető"
    }
  },
  "docplanner": {
    "issue": {
      "alreadyResolved": "A probléma már megoldottként megjelölve",
      "actionNotImplemented": "Ilyen típusú probléma esetén nincs automatikus művelet előkészítve",
      "notFound": "A probléma nem található"
    }
  },
  "hashtag": {
    "alreadyExists": "A megadott névvel rendelkező hashtag már létezik"
  },
  "generic": {
    "alreadyExists": "A megadott azonosítóval rendelkező objektum már létezik"
  },
  "partner": {
    "notMatch": "Nem található megfelelő szolgáltatás"
  }
}