/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Appointment } from '../model';
// @ts-ignore
import { AppointmentResignationReasonDto } from '../model';
// @ts-ignore
import { Dictionary } from '../model';
// @ts-ignore
import { DoctorVisitSlotDto } from '../model';
// @ts-ignore
import { SlotAvailability } from '../model';
/**
 * AppointingWidgetControllerApi - axios parameter creator
 * @export
 */
export const AppointingWidgetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {string} [visitPackageCode] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appoint: async (email: string, doctorVisitSlotDto: Array<DoctorVisitSlotDto>, visitPackageCode?: string, timezone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('appoint', 'email', email)
            // verify required parameter 'doctorVisitSlotDto' is not null or undefined
            assertParamExists('appoint', 'doctorVisitSlotDto', doctorVisitSlotDto)
            const localVarPath = `/api/appointing-widget/appoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (visitPackageCode !== undefined) {
                localVarQueryParameter['visitPackageCode'] = visitPackageCode;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVisitSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointing-widget/dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots: async (patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, visitPackageStageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientType' is not null or undefined
            assertParamExists('getSlots', 'patientType', patientType)
            // verify required parameter 'medicalService' is not null or undefined
            assertParamExists('getSlots', 'medicalService', medicalService)
            // verify required parameter 'visitType' is not null or undefined
            assertParamExists('getSlots', 'visitType', visitType)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getSlots', 'dateFrom', dateFrom)
            const localVarPath = `/api/appointing-widget/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientType !== undefined) {
                localVarQueryParameter['patientType'] = patientType;
            }

            if (medicalService !== undefined) {
                localVarQueryParameter['medicalService'] = medicalService;
            }

            if (visitType !== undefined) {
                localVarQueryParameter['visitType'] = visitType;
            }

            if (workPlaceId !== undefined) {
                localVarQueryParameter['workPlaceId'] = workPlaceId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (lookBack !== undefined) {
                localVarQueryParameter['lookBack'] = lookBack;
            }

            if (visitPackageStageId !== undefined) {
                localVarQueryParameter['visitPackageStageId'] = visitPackageStageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {string} dateFrom 
         * @param {'EACH' | 'FIRST' | 'NEXT'} visitOrder 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots1: async (doctorId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', dateFrom: string, visitOrder: 'EACH' | 'FIRST' | 'NEXT', days?: number, lookBack?: boolean, workPlaceId?: number, visitPackageStageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getSlots1', 'doctorId', doctorId)
            // verify required parameter 'medicalService' is not null or undefined
            assertParamExists('getSlots1', 'medicalService', medicalService)
            // verify required parameter 'visitType' is not null or undefined
            assertParamExists('getSlots1', 'visitType', visitType)
            // verify required parameter 'patientType' is not null or undefined
            assertParamExists('getSlots1', 'patientType', patientType)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getSlots1', 'dateFrom', dateFrom)
            // verify required parameter 'visitOrder' is not null or undefined
            assertParamExists('getSlots1', 'visitOrder', visitOrder)
            const localVarPath = `/api/appointing-widget/slot/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (medicalService !== undefined) {
                localVarQueryParameter['medicalService'] = medicalService;
            }

            if (visitType !== undefined) {
                localVarQueryParameter['visitType'] = visitType;
            }

            if (patientType !== undefined) {
                localVarQueryParameter['patientType'] = patientType;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (lookBack !== undefined) {
                localVarQueryParameter['lookBack'] = lookBack;
            }

            if (visitOrder !== undefined) {
                localVarQueryParameter['visitOrder'] = visitOrder;
            }

            if (workPlaceId !== undefined) {
                localVarQueryParameter['workPlaceId'] = workPlaceId;
            }

            if (visitPackageStageId !== undefined) {
                localVarQueryParameter['visitPackageStageId'] = visitPackageStageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotsAvailability: async (doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorVisitSlotDto' is not null or undefined
            assertParamExists('getSlotsAvailability', 'doctorVisitSlotDto', doctorVisitSlotDto)
            const localVarPath = `/api/appointing-widget/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVisitSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail: async (appointmentId: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('resendEmail', 'appointmentId', appointmentId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resendEmail', 'email', email)
            const localVarPath = `/api/appointing-widget/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppointmentResignationReasonDto} appointmentResignationReasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveResignationReason: async (appointmentResignationReasonDto: AppointmentResignationReasonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentResignationReasonDto' is not null or undefined
            assertParamExists('saveResignationReason', 'appointmentResignationReasonDto', appointmentResignationReasonDto)
            const localVarPath = `/api/appointing-widget/resignation-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentResignationReasonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointingWidgetControllerApi - functional programming interface
 * @export
 */
export const AppointingWidgetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointingWidgetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {string} [visitPackageCode] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appoint(email: string, doctorVisitSlotDto: Array<DoctorVisitSlotDto>, visitPackageCode?: string, timezone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appoint(email, doctorVisitSlotDto, visitPackageCode, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Dictionary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlots(patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, visitPackageStageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlots(patientType, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, visitPackageStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {string} dateFrom 
         * @param {'EACH' | 'FIRST' | 'NEXT'} visitOrder 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlots1(doctorId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', dateFrom: string, visitOrder: 'EACH' | 'FIRST' | 'NEXT', days?: number, lookBack?: boolean, workPlaceId?: number, visitPackageStageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlots1(doctorId, medicalService, visitType, patientType, dateFrom, visitOrder, days, lookBack, workPlaceId, visitPackageStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotsAvailability(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SlotAvailability>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotsAvailability(doctorVisitSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendEmail(appointmentId: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendEmail(appointmentId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppointmentResignationReasonDto} appointmentResignationReasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveResignationReason(appointmentResignationReasonDto: AppointmentResignationReasonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveResignationReason(appointmentResignationReasonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointingWidgetControllerApi - factory interface
 * @export
 */
export const AppointingWidgetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointingWidgetControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {string} [visitPackageCode] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appoint(email: string, doctorVisitSlotDto: Array<DoctorVisitSlotDto>, visitPackageCode?: string, timezone?: string, options?: any): Promise<Appointment> {
            return localVarFp.appoint(email, doctorVisitSlotDto, visitPackageCode, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionary(options?: any): Promise<Dictionary> {
            return localVarFp.getDictionary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots(patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, visitPackageStageId?: number, options?: any): Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }> {
            return localVarFp.getSlots(patientType, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, visitPackageStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
         * @param {string} dateFrom 
         * @param {'EACH' | 'FIRST' | 'NEXT'} visitOrder 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [visitPackageStageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots1(doctorId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', dateFrom: string, visitOrder: 'EACH' | 'FIRST' | 'NEXT', days?: number, lookBack?: boolean, workPlaceId?: number, visitPackageStageId?: number, options?: any): Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }> {
            return localVarFp.getSlots1(doctorId, medicalService, visitType, patientType, dateFrom, visitOrder, days, lookBack, workPlaceId, visitPackageStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotsAvailability(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: any): Promise<Array<SlotAvailability>> {
            return localVarFp.getSlotsAvailability(doctorVisitSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail(appointmentId: string, email: string, options?: any): Promise<void> {
            return localVarFp.resendEmail(appointmentId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppointmentResignationReasonDto} appointmentResignationReasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveResignationReason(appointmentResignationReasonDto: AppointmentResignationReasonDto, options?: any): Promise<void> {
            return localVarFp.saveResignationReason(appointmentResignationReasonDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointingWidgetControllerApi - object-oriented interface
 * @export
 * @class AppointingWidgetControllerApi
 * @extends {BaseAPI}
 */
export class AppointingWidgetControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
     * @param {string} [visitPackageCode] 
     * @param {string} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public appoint(email: string, doctorVisitSlotDto: Array<DoctorVisitSlotDto>, visitPackageCode?: string, timezone?: string, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).appoint(email, doctorVisitSlotDto, visitPackageCode, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public getDictionary(options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).getDictionary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
     * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
     * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
     * @param {string} dateFrom 
     * @param {number} [workPlaceId] 
     * @param {number} [days] 
     * @param {boolean} [lookBack] 
     * @param {number} [visitPackageStageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public getSlots(patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, visitPackageStageId?: number, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).getSlots(patientType, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, visitPackageStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
     * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
     * @param {'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR'} patientType 
     * @param {string} dateFrom 
     * @param {'EACH' | 'FIRST' | 'NEXT'} visitOrder 
     * @param {number} [days] 
     * @param {boolean} [lookBack] 
     * @param {number} [workPlaceId] 
     * @param {number} [visitPackageStageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public getSlots1(doctorId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', patientType: 'INFANT' | 'CHILD' | 'YOUTH' | 'ADULT' | 'SENIOR', dateFrom: string, visitOrder: 'EACH' | 'FIRST' | 'NEXT', days?: number, lookBack?: boolean, workPlaceId?: number, visitPackageStageId?: number, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).getSlots1(doctorId, medicalService, visitType, patientType, dateFrom, visitOrder, days, lookBack, workPlaceId, visitPackageStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public getSlotsAvailability(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).getSlotsAvailability(doctorVisitSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public resendEmail(appointmentId: string, email: string, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).resendEmail(appointmentId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppointmentResignationReasonDto} appointmentResignationReasonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointingWidgetControllerApi
     */
    public saveResignationReason(appointmentResignationReasonDto: AppointmentResignationReasonDto, options?: AxiosRequestConfig) {
        return AppointingWidgetControllerApiFp(this.configuration).saveResignationReason(appointmentResignationReasonDto, options).then((request) => request(this.axios, this.basePath));
    }
}
