import {
    AdminDocplannerControllerApi,
    AdminVisitApiControllerApi,
    AdminWorkPlanApiControllerApi,
    DoctorApiControllerApi, DoctorPackageControllerApi, DocumentStreamingControllerApi,
    DrugTherapyApiControllerApi,
    EHealthApiControllerApi, EpicrisisControllerApi,
    FeatureControllerApi,
    HarmonogramApiControllerApi,
    HealthStateSurveyApiControllerApi,
    MedicalEventApiControllerApi,
    MedicalRequestControllerApi,
    MessageApiControllerApi, PartnerControllerApi,
    PatientApiControllerApi, PaymentRefundControllerApi,
    ReferralApiControllerApi,
    SurveyZeroNoteControllerApi, SystemSettingsApiControllerApi,
    UserApiControllerApi,
    VisitApiControllerApi,
    VisitPackageControllerApi,
} from "@/api-client";
import {AdminPartnerControllerApi, IctalGtSeizureClassificationControllerApi} from "../api-client";

/**
 * @deprecated Inject ControllerApi class instead, for example:
 * @Inject() doctorApi: DoctorApiControllerApi;
 */
export const Api = {
    doctor: new DoctorApiControllerApi(),
    patient: new PatientApiControllerApi(),
    user: new UserApiControllerApi(),
    healthStateSurvey: new HealthStateSurveyApiControllerApi(),
    harmonogram: new HarmonogramApiControllerApi(),
    medicalEvent: new MedicalEventApiControllerApi(),
    medicalRequest: new MedicalRequestControllerApi(),
    drugTherapy: new DrugTherapyApiControllerApi(),
    eHealth: new EHealthApiControllerApi(),
    referral: new ReferralApiControllerApi(),
    surveyZeroNote: new SurveyZeroNoteControllerApi(),
    ictalGtSeizureClassification: new IctalGtSeizureClassificationControllerApi(),
    visitPackage: new VisitPackageControllerApi(),
    message: new MessageApiControllerApi(),
    visit: new VisitApiControllerApi(),
    feature: new FeatureControllerApi(),
    paymentRefund: new PaymentRefundControllerApi(),
    doctorPackage: new DoctorPackageControllerApi(),
    epicrisis: new EpicrisisControllerApi(),
    partner: new PartnerControllerApi(),
    documentStreaming: new DocumentStreamingControllerApi(),
}

/**
 * @deprecated Inject ControllerApi class instead, for example:
 * @Inject() doctorApi: DoctorApiControllerApi;
 */
export const AdminApi = {
    workPlan: new AdminWorkPlanApiControllerApi(),
    docplanner: new AdminDocplannerControllerApi(),
    systemSettings: new SystemSettingsApiControllerApi(),
    partner: new AdminPartnerControllerApi(),
    visit: new AdminVisitApiControllerApi(),
}