/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AvailablePricelistDto } from '../model';
// @ts-ignore
import { DoctorPricelistItemDto } from '../model';
// @ts-ignore
import { DoctorVisitsInfoDto } from '../model';
/**
 * DoctorPricelistApiControllerApi - axios parameter creator
 * @export
 */
export const DoctorPricelistApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {'DEFAULT' | 'ALL' | 'NEUROSCREEN'} mode 
         * @param {boolean} isConsultation 
         * @param {number} [partnerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForPatient: async (patientId: number, mode: 'DEFAULT' | 'ALL' | 'NEUROSCREEN', isConsultation: boolean, partnerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAvailablePricelistForPatient', 'patientId', patientId)
            // verify required parameter 'mode' is not null or undefined
            assertParamExists('getAvailablePricelistForPatient', 'mode', mode)
            // verify required parameter 'isConsultation' is not null or undefined
            assertParamExists('getAvailablePricelistForPatient', 'isConsultation', isConsultation)
            const localVarPath = `/api/doctor-pricelist/available/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (isConsultation !== undefined) {
                localVarQueryParameter['isConsultation'] = isConsultation;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForPatientFromReferral: async (referralId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('getAvailablePricelistForPatientFromReferral', 'referralId', referralId)
            const localVarPath = `/api/doctor-pricelist/referral/{referralId}`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForVisitReassign: async (visitPublicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitPublicId' is not null or undefined
            assertParamExists('getAvailablePricelistForVisitReassign', 'visitPublicId', visitPublicId)
            const localVarPath = `/api/doctor-pricelist/reassign/available/{visitPublicId}`
                .replace(`{${"visitPublicId"}}`, encodeURIComponent(String(visitPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableVisitsForReferral: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAvailableVisitsForReferral', 'patientId', patientId)
            const localVarPath = `/api/doctor-pricelist/consultation/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricelistForMedicalService: async (medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', referralId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalService' is not null or undefined
            assertParamExists('getPricelistForMedicalService', 'medicalService', medicalService)
            const localVarPath = `/api/doctor-pricelist/{medicalService}`
                .replace(`{${"medicalService"}}`, encodeURIComponent(String(medicalService)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referralId !== undefined) {
                localVarQueryParameter['referralId'] = referralId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorPricelistApiControllerApi - functional programming interface
 * @export
 */
export const DoctorPricelistApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorPricelistApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {'DEFAULT' | 'ALL' | 'NEUROSCREEN'} mode 
         * @param {boolean} isConsultation 
         * @param {number} [partnerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePricelistForPatient(patientId: number, mode: 'DEFAULT' | 'ALL' | 'NEUROSCREEN', isConsultation: boolean, partnerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AvailablePricelistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePricelistForPatient(patientId, mode, isConsultation, partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePricelistForPatientFromReferral(referralId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AvailablePricelistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePricelistForPatientFromReferral(referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailablePricelistForVisitReassign(visitPublicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorVisitsInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailablePricelistForVisitReassign(visitPublicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableVisitsForReferral(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorVisitsInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableVisitsForReferral(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricelistForMedicalService(medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', referralId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorPricelistItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricelistForMedicalService(medicalService, referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorPricelistApiControllerApi - factory interface
 * @export
 */
export const DoctorPricelistApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorPricelistApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {'DEFAULT' | 'ALL' | 'NEUROSCREEN'} mode 
         * @param {boolean} isConsultation 
         * @param {number} [partnerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForPatient(patientId: number, mode: 'DEFAULT' | 'ALL' | 'NEUROSCREEN', isConsultation: boolean, partnerId?: number, options?: any): Promise<AvailablePricelistDto> {
            return localVarFp.getAvailablePricelistForPatient(patientId, mode, isConsultation, partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} referralId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForPatientFromReferral(referralId: number, options?: any): Promise<AvailablePricelistDto> {
            return localVarFp.getAvailablePricelistForPatientFromReferral(referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailablePricelistForVisitReassign(visitPublicId: string, options?: any): Promise<Array<DoctorVisitsInfoDto>> {
            return localVarFp.getAvailablePricelistForVisitReassign(visitPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableVisitsForReferral(patientId: number, options?: any): Promise<Array<DoctorVisitsInfoDto>> {
            return localVarFp.getAvailableVisitsForReferral(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricelistForMedicalService(medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', referralId?: number, options?: any): Promise<Array<DoctorPricelistItemDto>> {
            return localVarFp.getPricelistForMedicalService(medicalService, referralId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorPricelistApiControllerApi - object-oriented interface
 * @export
 * @class DoctorPricelistApiControllerApi
 * @extends {BaseAPI}
 */
export class DoctorPricelistApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {'DEFAULT' | 'ALL' | 'NEUROSCREEN'} mode 
     * @param {boolean} isConsultation 
     * @param {number} [partnerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPricelistApiControllerApi
     */
    public getAvailablePricelistForPatient(patientId: number, mode: 'DEFAULT' | 'ALL' | 'NEUROSCREEN', isConsultation: boolean, partnerId?: number, options?: AxiosRequestConfig) {
        return DoctorPricelistApiControllerApiFp(this.configuration).getAvailablePricelistForPatient(patientId, mode, isConsultation, partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} referralId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPricelistApiControllerApi
     */
    public getAvailablePricelistForPatientFromReferral(referralId: number, options?: AxiosRequestConfig) {
        return DoctorPricelistApiControllerApiFp(this.configuration).getAvailablePricelistForPatientFromReferral(referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} visitPublicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPricelistApiControllerApi
     */
    public getAvailablePricelistForVisitReassign(visitPublicId: string, options?: AxiosRequestConfig) {
        return DoctorPricelistApiControllerApiFp(this.configuration).getAvailablePricelistForVisitReassign(visitPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPricelistApiControllerApi
     */
    public getAvailableVisitsForReferral(patientId: number, options?: AxiosRequestConfig) {
        return DoctorPricelistApiControllerApiFp(this.configuration).getAvailableVisitsForReferral(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
     * @param {number} [referralId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPricelistApiControllerApi
     */
    public getPricelistForMedicalService(medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', referralId?: number, options?: AxiosRequestConfig) {
        return DoctorPricelistApiControllerApiFp(this.configuration).getPricelistForMedicalService(medicalService, referralId, options).then((request) => request(this.axios, this.basePath));
    }
}
