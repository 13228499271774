/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FinalizeChunkImportDto } from '../model';
// @ts-ignore
import { MedicalDocumentDto } from '../model';
/**
 * DocumentStreamingControllerApi - axios parameter creator
 * @export
 */
export const DocumentStreamingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendChunk: async (medicalDocumentId: number, chunkNumber: number, chunk: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentId' is not null or undefined
            assertParamExists('appendChunk', 'medicalDocumentId', medicalDocumentId)
            // verify required parameter 'chunkNumber' is not null or undefined
            assertParamExists('appendChunk', 'chunkNumber', chunkNumber)
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('appendChunk', 'chunk', chunk)
            const localVarPath = `/api/streaming/document/chunk/{medicalDocumentId}/append`
                .replace(`{${"medicalDocumentId"}}`, encodeURIComponent(String(medicalDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (chunkNumber !== undefined) {
                localVarQueryParameter['chunkNumber'] = chunkNumber;
            }


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentChunksPlaceholder: async (medicalDocumentDto: MedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentDto' is not null or undefined
            assertParamExists('createDocumentChunksPlaceholder', 'medicalDocumentDto', medicalDocumentDto)
            const localVarPath = `/api/streaming/document/chunk/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeChunkImportDto} finalizeChunkImportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeChunksImport: async (finalizeChunkImportDto: FinalizeChunkImportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeChunkImportDto' is not null or undefined
            assertParamExists('finalizeChunksImport', 'finalizeChunkImportDto', finalizeChunkImportDto)
            const localVarPath = `/api/streaming/document/chunk/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeChunkImportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument: async (uploads: Array<any>, data?: MedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploads' is not null or undefined
            assertParamExists('saveDocument', 'uploads', uploads)
            const localVarPath = `/api/streaming/document/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamVideo: async (medicalDocumentId: number, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentId' is not null or undefined
            assertParamExists('streamVideo', 'medicalDocumentId', medicalDocumentId)
            const localVarPath = `/api/streaming/document/stream/{medicalDocumentId}`
                .replace(`{${"medicalDocumentId"}}`, encodeURIComponent(String(medicalDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined && range !== null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentStreamingControllerApi - functional programming interface
 * @export
 */
export const DocumentStreamingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentStreamingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appendChunk(medicalDocumentId, chunkNumber, chunk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentChunksPlaceholder(medicalDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeChunkImportDto} finalizeChunkImportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeChunksImport(finalizeChunkImportDto: FinalizeChunkImportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeChunksImport(finalizeChunkImportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDocument(uploads, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamVideo(medicalDocumentId: number, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamVideo(medicalDocumentId, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentStreamingControllerApi - factory interface
 * @export
 */
export const DocumentStreamingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentStreamingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, options?: any): Promise<void> {
            return localVarFp.appendChunk(medicalDocumentId, chunkNumber, chunk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: any): Promise<number> {
            return localVarFp.createDocumentChunksPlaceholder(medicalDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeChunkImportDto} finalizeChunkImportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeChunksImport(finalizeChunkImportDto: FinalizeChunkImportDto, options?: any): Promise<void> {
            return localVarFp.finalizeChunksImport(finalizeChunkImportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: any): Promise<void> {
            return localVarFp.saveDocument(uploads, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamVideo(medicalDocumentId: number, range?: string, options?: any): Promise<string> {
            return localVarFp.streamVideo(medicalDocumentId, range, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentStreamingControllerApi - object-oriented interface
 * @export
 * @class DocumentStreamingControllerApi
 * @extends {BaseAPI}
 */
export class DocumentStreamingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} medicalDocumentId 
     * @param {number} chunkNumber 
     * @param {any} chunk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentStreamingControllerApi
     */
    public appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, options?: AxiosRequestConfig) {
        return DocumentStreamingControllerApiFp(this.configuration).appendChunk(medicalDocumentId, chunkNumber, chunk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDocumentDto} medicalDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentStreamingControllerApi
     */
    public createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: AxiosRequestConfig) {
        return DocumentStreamingControllerApiFp(this.configuration).createDocumentChunksPlaceholder(medicalDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeChunkImportDto} finalizeChunkImportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentStreamingControllerApi
     */
    public finalizeChunksImport(finalizeChunkImportDto: FinalizeChunkImportDto, options?: AxiosRequestConfig) {
        return DocumentStreamingControllerApiFp(this.configuration).finalizeChunksImport(finalizeChunkImportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} uploads 
     * @param {MedicalDocumentDto} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentStreamingControllerApi
     */
    public saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig) {
        return DocumentStreamingControllerApiFp(this.configuration).saveDocument(uploads, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medicalDocumentId 
     * @param {string} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentStreamingControllerApi
     */
    public streamVideo(medicalDocumentId: number, range?: string, options?: AxiosRequestConfig) {
        return DocumentStreamingControllerApiFp(this.configuration).streamVideo(medicalDocumentId, range, options).then((request) => request(this.axios, this.basePath));
    }
}
