/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MedicalServiceGroupDto
 */
export interface MedicalServiceGroupDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalServiceGroupDto
     */
    'type'?: MedicalServiceGroupDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalServiceGroupDtoTypeEnum {
    AbsentConsultation = 'ABSENT_CONSULTATION',
    Visit0 = 'VISIT_0',
    MigrationVisit = 'MIGRATION_VISIT',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    EegConsultation = 'EEG_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}


